<template>
  <slice
    :id="sliceId"
    class="slice--products slice--products--auto"
    :class="{
      'with-title': withTitle,
      'slice--products--slider': isASlider,
      'slice--products--grid': isNormalList || isRefCo,
      'slice--products--with-filters': hasFilters,
      'slice--products--opened-filter': openedFilters,
      'slice--products--grid--home': slice.primary.list_type === 'page-accueil',
    }"
    :data-slice-type="slice.slice_type"
    :data-slice-variation="slice.variation"
    @in="sliceIn()"
    @out="$emit('out')"
  >
    <div
      v-if="withTitle"
      :class="{
        container: isASlider,
      }"
    >
      <div class="row-md justify-center">
        <div
          class="slice--products__title col-md-10 d-md-flex text-center mb-3"
          :class="{
            'col-xlg-12 justify-space-between align-end text-md-left':
              isASlider,
            'justify-center': !hasLink,
          }"
        >
          <div class="pr-md-2 mb-2 mb-md-0">
            <prismic-title :title="slice.primary.title" class="h3" />
            <rich-text v-if="hasDescription" :content="slice.primary.desc" />
          </div>

          <div v-if="hasLink" class="d-flex align-center justify-center">
            <primary-button
              type="nuxt-link"
              :to="$contextPrismicPath(link)"
              :text="slice.primary.link_text"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="hasFilters && !isASlider"
      data-v-sticky-container
      class="grid-filter"
      :class="{ 'hide-filters': hideFilters }"
    >
      <transition name="filter-refresh">
        <div v-if="refreshing" key="refreshing" class="grid-filter__refreshing">
          <div v-sticky="sticky">
            <div
              class="grid-filter__refreshing__inner h4"
              data-v-sticky-refreshing-inner
            >
              <icon-loading class="mr-1" />
              <span class="text-secondary">{{ $t('loading') }}</span>
            </div>
          </div>
        </div>
      </transition>

      <div class="grid-filter__filters" data-v-sticky-inner>
        <Filters
          :slice-id="id"
          :filters="filters"
          class="pl-md-1 pr-md-1 mb-0-4 mb-md-1"
          @update="onFiltersUpdate"
          @request-reduce="reduceFilters"
          @filter-opened="onFilterOpened"
          @filter-closed="onFilterClosed"
        />
      </div>

      <div class="open-filters" :class="{ visible: hideFilters }">
        <div v-sticky="stickyOpenFilters">
          <div class="open-filters__inner" data-v-sticky-open-filters-inner>
            <button-link with-icon icon="open" @click="reduceFilters">
              <span class="text-extra-small text-uppercase w-500">{{
                $t('action.open_filters')
              }}</span>
            </button-link>
          </div>
        </div>
      </div>

      <div
        v-if="!openedFilters"
        ref="gridOfProducts"
        v-bind="containerAttributes"
      >
        <component
          :is="cardComponent()"
          v-for="(product, i) in orderedProducts"
          :key="`${product.prismic.id}-${i}`"
          :prismic-product="product.prismic"
          :shopify-product="product.shopify"
          :additional-inventory="product.additionalInventory"
          :is-first="
            firstSlice &&
            index === 0 &&
            i === 0 &&
            slice.primary.list_type !== 'agenda-de-retour-un-jour'
          "
          :soon-in-sell="sellsWillOpenSoon"
          :bis-only="slice.primary.list_type === 'agenda-de-retour-un-jour'"
          v-bind="productCardAttributes(i, product)"
        />
      </div>
    </div>

    <component
      :is="containerComponent()"
      v-else-if="init"
      ref="gridOfProducts"
      v-bind="containerAttributes"
      data-v-sticky-container
    >
      <component
        :is="cardComponent(product.type)"
        v-for="(product, i) in orderedProducts"
        :key="`${product.prismic.id}-${i}`"
        :prismic-product="product.prismic"
        :shopify-product="product.shopify"
        :additional-inventory="product.additionalInventory"
        :is-first="
          firstSlice &&
          index === 0 &&
          i === 0 &&
          slice.primary.list_type !== 'agenda-de-retour-un-jour'
        "
        :soon-in-sell="sellsWillOpenSoon"
        :bis-only="slice.primary.list_type === 'agenda-de-retour-un-jour'"
        v-bind="productCardAttributes(i, product)"
      />
    </component>
    <div v-else>
      <div
        v-if="isASlider"
        class="container slider"
        :style="{
          display: 'grid',
          gridTemplateColumns: `repeat(3, 380px)`,
          gap: '30px',
        }"
      >
        <div v-for="i in 3" :key="i" class="pb-3">
          <div class="skeleton" style="padding-top: 130.556%"></div>
          <div
            class="skeleton skeleton--bar mt-1"
            style="width: 130px; height: 24px"
          ></div>
          <div class="d-flex" style="margin-top: 4px">
            <div
              class="skeleton skeleton--bar"
              style="width: 80px; height: 20px; margin-right: 15px"
            ></div>
            <div
              class="skeleton skeleton--bar"
              style="width: 80px; height: 20px"
            ></div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="grid-of-products"
        :style="{
          '--number-of-lines':
            slice.primary.list_type === 'page-accueil' ? 1 : numberOfLines,
        }"
      >
        <div
          v-for="i in numberOfLines"
          :key="i"
          class="skeleton"
          :style="{
            '--product-grid-column-mobile': '1/13',
            '--product-grid-column-desktop': '1/13',
            '--product-grid-row-mobile': `${i}/${i + 1}`,
            '--product-grid-row-desktop': `${i}/${i + 1}`,
          }"
        ></div>
      </div>
    </div>

    <div v-if="isNormalList" v-html="customGridStyle"></div>

    <div
      v-if="showBis"
      key="create-alert"
      class="create-alert--card create-alert--card--0"
    >
      <div class="inner p-relative">
        <div>
          <p class="h4 mb-2">{{ slice.primary.bis_title }}</p>
          <form-klaviyo-email
            id="klaviyo-email-auto"
            :button-label="slice.primary.bis_button_label"
            :button-aspect="$vssWidth > 769 ? 'light' : 'dark'"
            :context="$route.params.context"
          />
        </div>
        <icon-logo-footer class="icon--bg" />
      </div>
    </div>
    <div
      v-else-if="showLoadMore"
      v-waypoint="{
        active: true,
        callback: onWaypoint,
        options: intersectionOptions,
      }"
      class="col-xs-12"
    >
      <div
        v-if="load || refreshing"
        class="load-more d-flex justify-center align-center pt-2 pb-2"
      >
        <icon-loading class="mr-0-6" />
        <span>{{ $t('loading') }}</span>
      </div>
    </div>
  </slice>
</template>

<script>
import { mapMutations } from 'vuex'
import NuxtSSRScreenSize from 'nuxt-ssr-screen-size'
import * as prismic from '@prismicio/client'
import { isFuture } from 'date-fns'

import {
  productGraphql,
  productGraphqlRefCo,
} from '@/slices/ProductList/productGraphql'

import Slice from '@/components/Slices/Slice'
import PrismicTitle from '@/components/Title/PrismicTitle'
import RichText from '@/components/RichText/RichText'
import PrimaryButton from '@/components/buttons/Primary'
import ButtonLink from '@/components/buttons/Link'
import IconLogoFooter from '@/components/icons/LogoFooter'
import IconLoading from '@/components/icons/Loading'
import Slider from '@/components/slider/Slider'
import DefaultDiv from '@/components/div/Default'
import ProductCard from '@/components/Product/Card'
import ProductCardV2 from '@/components/Product/Card.v2'
import ProductCardRefCo from '@/components/Product/CardRefCo'
import StockCard from '@/components/stock/StockCard'

import PrismicProduct from '@/entities/prismic/Product'
import ShopifyProduct from '@/entities/shopify/Product'

import services from '@/services'
import {
  PRISMIC_CONTEXT,
  COLLECTION_PRODUCT_TYPES,
  LS_PRODUCT,
  LS_PRODUCT_CONFIG,
  LS_ORDERED_PRODUCTS,
} from '@/const'
import { NUMBER_BY_LINE } from '@/const/gallery'
import { extractMetafieldsOfCollection } from '@/utils/extractMetafields'
import ShopifyBase64 from '@/utils/shopifyBase64'
import { EVENTS } from '@/const/events'

const PREDICATES = {
  'agenda-bientot-dispo': [
    prismic.filter.at('my.product.visible', true),
    prismic.filter.at('my.product.status', 'agenda'),
    prismic.filter.dateAfter('my.product.startdate', new Date().getTime()),
  ],
  'agenda-de-retour-un-jour': [
    prismic.filter.at('my.product.visible', true),
    prismic.filter.at('my.product.status', 'agenda'),
    prismic.filter.missing('my.product.startdate'),
  ],
  'en-precommande': [
    prismic.filter.at('my.product.visible', true),
    prismic.filter.at('my.product.status', 'precommande'),
  ],
  éternels: [
    prismic.filter.at('my.product.visible', true),
    prismic.filter.at('my.product.status', 'precommande'),
    prismic.filter.at('my.product.market_product_type', 'eternel'),
  ],
  'en-stock': [
    prismic.filter.at('my.product.visible', true),
    prismic.filter.at('my.product.status', 'stock'),
  ],
  'page-accueil': [prismic.filter.at('my.product.on_home', true)],
}

const ORDERINGS = {
  'agenda-bientot-dispo': {
    field: 'my.product.startdate',
    direction: 'asc',
  },
  'agenda-de-retour-un-jour': {
    field: 'document.last_publication_date',
    direction: 'desc',
  },
  'en-precommande': {
    field: 'document.last_publication_date',
    direction: 'desc',
  },
  éternels: {
    field: 'document.last_publication_date',
    direction: 'desc',
  },
  'en-stock': {
    field: 'document.last_publication_date',
    direction: 'desc',
  },
  'page-accueil': {
    field: 'document.last_publication_date',
    direction: 'desc',
  },
}

const PAGESIZE = {
  'agenda-bientot-dispo': 100,
  'agenda-de-retour-un-jour': 100,
  'en-precommande': 100,
  'en-stock': 100,
  éternels: 100,
  'page-accueil': 1,
}

export default {
  name: 'ProductListAuto',
  components: {
    Slice,
    PrismicTitle,
    RichText,
    ButtonLink,
    PrimaryButton,
    FormKlaviyoEmail: () =>
      import(
        '@/components/form/klaviyo/Email' /* webpackChunkName: "form-klaviyo-email" */
      ),
    IconLogoFooter,
    IconLoading,
    Filters: () => ({
      component: import(
        '@/components/filters/Default' /* webpackChunkName: 'filters' */
      ),
    }),
  },
  mixins: [NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],
  props: {
    id: { type: String, default: '' },
    slice: { type: Object, required: true },
    index: { type: Number, default: null },
    firstSlice: { type: Boolean, default: false },
    origin: { type: String, required: true },
  },
  data: () => ({
    products: [],
    orderedProducts: [],
    config: null,
    init: false,
    load: false,
    refreshing: false,
    hasCheckIntegrity: false,
    intersectionOptions: {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: [0, 1],
    },
    page: 1,
    pageSize: 30,
    loadMultiplePage: 0,
    numberOfLines: 20,
    listDisplay: 'grid',
    totalPage: 0,
    hasNextPage: false,
    currentEndCursor: null,
    filters: {},
    selectedFilters: {},
    hideFilters: true,
    openedFilters: false,
    sticky: {
      containerSelector: '[data-v-sticky-container]',
      innerWrapperSelector: '[data-v-sticky-refreshing-inner]',
      resizeSensor: true,
      minWidth: 769,
    },
    stickyOpenFilters: {
      containerSelector: '[data-v-sticky-container]',
      innerWrapperSelector: '[data-v-sticky-open-filters-inner]',
      resizeSensor: true,
      minWidth: 769,
    },
    sellsWillOpenSoon: false,
    forceListTypeAgenda: false,
    stockCollection: null,
    stockDates: null,
  }),
  async fetch() {
    if (this.slice.primary.config && this.slice.primary.config.id) {
      await this.loadConfig()
    }

    switch (this.slice.primary.list_type) {
      case 'en-stock': {
        this.prepareFilters()
        await this.loadShopifyCollection()
        break
      }
      case 'agenda-de-retour-un-jour': {
        this.prepareFilters()
        await this.loadShopifyCollection()
        break
      }
      case 'agenda-bientot-dispo': {
        await this.loadStockCollection()
        await this.loadProducts()

        break
      }
      default: {
        await this.loadProducts()
        break
      }
    }
  },
  computed: {
    sliceId() {
      return this.slice.id.replace('$', '')
    },
    isNormalList() {
      return (
        this.slice.primary.products_type === COLLECTION_PRODUCT_TYPES.NORMAL
      )
    },
    isASlider() {
      return (
        this.slice.primary.products_type === COLLECTION_PRODUCT_TYPES.SLIDER
      )
    },
    isRefCo() {
      return (
        this.slice.primary.products_type === COLLECTION_PRODUCT_TYPES.REF_CO
      )
    },
    withTitle() {
      return prismic.isFilled.title(this.slice.primary.title)
    },
    hasDescription() {
      return prismic.isFilled.richText(this.slice.primary.desc)
    },
    hasLink() {
      if (this.origin === 'account') {
        if (this.$route.params.context === 'f') {
          return prismic.isFilled.link(this.slice.primary.link_f)
        }

        return prismic.isFilled.link(this.slice.primary.link_h)
      }

      return prismic.isFilled.link(this.slice.primary.link)
    },
    link() {
      if (!this.hasLink) {
        return null
      }

      if (this.origin === 'account') {
        if (this.$route.params.context === 'f') {
          return this.slice.primary.link_f
        }

        return this.slice.primary.link_h
      }

      return this.slice.primary.link
    },

    showBis() {
      if (this.openedFilters) {
        return false
      }

      return (
        this.slice.primary.list_type === 'agenda-bientot-dispo' &&
        this.slice.primary.products_type === 'normal' &&
        prismic.isFilled.richText(this.slice.primary.bis_title)
      )
    },
    showLoadMore() {
      if (
        this.slice.primary.list_type === 'en-stock' ||
        this.slice.primary.list_type === 'agenda-de-retour-un-jour'
      ) {
        return this.hasNextPage && !this.openedFilters
      }

      return (
        this.slice.primary.list_type === 'agenda-de-retour-un-jour' &&
        this.slice.primary.products_type === 'normal' &&
        this.page <= this.totalPage
      )
    },
    withConfig() {
      return this.slice.primary.config && this.slice.primary.config.id
    },
    containerAttributes() {
      if (this.isASlider) {
        return {
          numberOfChildren: this.orderedProducts.length,
          numberOnScreen:
            this.$vssWidth > 1445
              ? 4
              : this.$vssWidth > 769
              ? 3
              : this.$vssWidth > 600
              ? 2
              : 1,
          maxChildWidth: this.$vssWidth < 600 ? 300 : null,
          loadingWidth: 380,
          loadingStyle: {
            height: '0px',
            paddingBottom: '155.258%',
          },
          navAsSquare: true,
          class: 'container',
        }
      }

      if (this.isRefCo) {
        return {}
      }

      return {
        class: `grid-of-products grid-of-products--${this.sliceId}`,
      }
    },
    gridItemPositions() {
      if (this.isNormalList) {
        return this.getGridItemsPositions()
      }

      return []
    },
    numberOfLinesOnDesktop() {
      if (this.isNormalList && this.currentGrid.desktop) {
        return this.getNumberOfLines(this.currentGrid.desktop)
      }
      return 0
    },
    numberOfLinesOnMobile() {
      if (this.isNormalList && this.currentGrid.mobile) {
        return this.getNumberOfLines(this.currentGrid.mobile)
      }
      return 0
    },
    customGridStyle() {
      let gridTemplateRows = ''

      gridTemplateRows = this.getGridLines(this.currentGrid.mobile)
        .map((n) => {
          switch (n) {
            case 1:
              return 'var(--default-grid-height-one-per-line)'
            case 2:
              return 'var(--default-grid-height-two-per-line)'
          }
          return ''
        })
        .join(' ')
      gridTemplateRows = `grid-template-rows: ${gridTemplateRows};`

      return `<style>
          @media screen and (max-width: 769px) {
            .grid-of-products--${this.sliceId} {
              --number-of-lines: ${this.numberOfLinesOnMobile};
              ${gridTemplateRows}
            }
          }

          @media screen and (min-width: 769px) {
            .grid-of-products--${this.sliceId} {
              --number-of-lines: ${this.numberOfLinesOnDesktop};
            }
          }
        </style>`
    },
    hasFilters() {
      if (this.slice.primary.activate_filters && this.init) {
        return (
          typeof this.filters.product_type !== 'undefined' ||
          typeof this.filters.sizes !== 'undefined'
        )
      }
      return false
    },
    currentGrid() {
      if (this.listDisplay === 'list') {
        return {
          desktop: [{ ligne: '3 produits' }],
          mobile: [{ ligne: '2 produits' }],
        }
      }

      return {
        desktop: this.config
          ? this.config.desktop_matrix
          : process.env.DEFAULT_GALLERIES?.desktop || {},
        mobile: this.config
          ? this.config.mobile_matrix
          : process.env.DEFAULT_GALLERIES?.mobile || {},
      }
    },
    hasStockDates() {
      return (
        this.slice.primary.list_type === 'agenda-bientot-dispo' &&
        this.stockDates !== null
      )
    },
    showStockCard() {
      if (this.hasStockDates) {
        if (this.stockDates.start_date_web) {
          const now = new Date()

          if (this.stockDates.start_date_web) {
            return now.getTime() < this.stockDates.start_date_web.getTime()
          }
        }
      }
      return false
    },
  },
  watch: {
    products(newProducts) {
      if (process.client) {
        this.saveProductInSession(newProducts)
      }
    },
    config(newConfig) {
      if (newConfig) {
        this.saveConfigInSession(newConfig)
      }
    },
    orderedProducts(newOrderedProducts) {
      if (process.client) {
        this.saveOrderedProductsInSession(newOrderedProducts)
      }
    },
  },
  mounted() {
    if (!this.$fetchState.pending && this.init) {
      if (!this.populateFromSession()) {
        this.populateDatas()
      }

      if (
        this.slice.primary.list_type === 'en-stock' ||
        this.slice.primary.list_type === 'agenda-de-retour-un-jour'
      ) {
        this.prepareFilters()
        this.loadShopifyCollection(false, false)
      }
    }
  },
  methods: {
    ...mapMutations({
      setScroll: 'behavior/SET_SCROLL',
    }),
    async checkIntegrity() {
      await this.$prismic.client
        .get({
          filters: [
            ...PREDICATES[this.slice.primary.list_type],
            ...(process.env.SITE_VERSION === 'default'
              ? [prismic.filter.not('document.tags', ['AB Test'])]
              : []),
            prismic.filter.any('my.product.context', [
              'Tous',
              this.$route.params.context
                ? PRISMIC_CONTEXT[this.$route.params.context]
                : PRISMIC_CONTEXT.h,
            ]),
          ],
          graphQuery: `{
            product {
              product_name
            }
          }`,
          pageSize: 1,
          page: 1,
          lang: this.$i18n.locale,
          orderings: [ORDERINGS[this.slice.primary.list_type]],
        })
        .then((response) => {
          if (this.products.length !== response.total_results_size) {
            this.init = false
            this.page = 1

            this.$nextTick().then(() => {
              this.products = []
              this.loadProducts()
            })
          }
        })

      this.hasCheckIntegrity = true
    },
    async loadProducts() {
      try {
        this.load = true
        let products = await this.$prismic.client
          .get({
            filters: [
              ...PREDICATES[this.slice.primary.list_type],
              ...(process.env.SITE_VERSION === 'default'
                ? [prismic.filter.not('document.tags', ['AB Test'])]
                : []),
              prismic.filter.any('my.product.context', [
                'Tous',
                this.$route.params.context
                  ? PRISMIC_CONTEXT[this.$route.params.context]
                  : PRISMIC_CONTEXT.h,
              ]),
            ],
            graphQuery: this.isRefCo ? productGraphqlRefCo : productGraphql,
            pageSize: PAGESIZE[this.slice.primary.list_type],
            page: this.page,
            lang: this.$i18n.locale,
            orderings: [ORDERINGS[this.slice.primary.list_type]],
          })
          .then(async (response) => {
            if (
              this.slice.primary.list_type === 'page-accueil' &&
              response.results.length === 0
            ) {
              // SI PAS DE PRODUIT EN VENTE ALORS ON AFFICHE LE PROCHAIN PRODUIT SUR LA PAGE D'ACCUEIL
              this.forceListTypeAgenda = true
              return await this.$prismic.client
                .get({
                  filters: [
                    ...PREDICATES['agenda-bientot-dispo'],
                    prismic.filter.any('my.product.context', [
                      'Tous',
                      this.$route.params.context
                        ? PRISMIC_CONTEXT[this.$route.params.context]
                        : PRISMIC_CONTEXT.h,
                    ]),
                  ],
                  graphQuery: productGraphql,
                  pageSize: 1,
                  page: this.page,
                  lang: this.$i18n.locale,
                  orderings: [ORDERINGS['agenda-bientot-dispo']],
                })
                .then((response) => {
                  this.totalPage = response.total_pages
                  this.page++
                  return response.results.map((r) => ({
                    prismic: new PrismicProduct(r),
                    additionalInventory: 0,
                  }))
                })
            }

            this.totalPage = response.total_pages
            this.page++
            return response.results.map((r) => ({
              prismic: new PrismicProduct(r),
              additionalInventory: 0,
            }))
          })

        if (
          process.env.ABTEST_UID_SWITCH &&
          process.env.ABTEST_UID_SWITCH.products.length > 0
        ) {
          const uidToOverride = process.env.ABTEST_UID_SWITCH.products.filter(
            (p) => products.some((pr) => pr.prismic.uid === p.origin)
          )

          if (uidToOverride.length > 0) {
            const overridedProducts = await this.$prismic.client
              .getByUIDs(
                'product',
                uidToOverride.map((p) => p.alter),
                {
                  lang: this.$i18n.locale,
                  graphQuery: productGraphql,
                  pageSize: uidToOverride.length,
                }
              )
              .then((r) => r.results.map((pr) => new PrismicProduct(pr)))

            products = products.map((product) => {
              const overridedProduct = overridedProducts.find((op) => {
                const linkBetweenProducts = uidToOverride.find(
                  (l) => l.origin === product.prismic.uid
                )

                if (linkBetweenProducts) {
                  return linkBetweenProducts.alter === op.uid
                }

                return false
              })

              if (overridedProduct) {
                product.prismic = overridedProduct
              }

              return product
            })
          }
        }

        const shopifyProducts =
          await services.productService.getShopifyProducts(
            products.map((p) => p.prismic.shopifyId),
            this.$i18n.locale
          )

        for (const p of products) {
          const shopifyProduct = shopifyProducts
            .filter((s) => s !== null)
            .find((s) => s.id.includes(p.prismic.shopifyId))

          if (shopifyProduct) {
            p.shopify = new ShopifyProduct(shopifyProduct)
          }

          if (p.prismic.inventoryLinks) {
            const linkedProduct =
              await services.productService.getShopifyProductsInventory(
                p.prismic.inventoryLinks
              )

            p.additionalInventory = linkedProduct.reduce((acc, curr) => {
              return acc + Math.abs(curr.totalInventory)
            }, 0)
          }
        }

        const prismicUids = products.map((p) => p.prismic.uid)

        if (
          this.products.length === 0 ||
          this.products.some((p) => !prismicUids.includes(p.prismic.uid))
        ) {
          this.products = products
        } else {
          const filteredProducts = this.products.filter(
            (p) => !prismicUids.includes(p.prismic.uid)
          )
          this.products = [...this.products, ...filteredProducts]
        }

        this.populateDatas()

        this.init = true
        this.load = false
      } catch (err) {
        this.$logError.captureException(err)
      }
    },
    async loadConfig() {
      this.config = await this.$prismic.client
        .getByID(this.slice.primary.config.id, { lang: this.$i18n.locale })
        .then((response) => {
          return response.data
        })
    },
    async loadShopifyCollection(
      next = false,
      scrollToTop = false,
      initialProducts = []
    ) {
      if (!prismic.isFilled.keyText(this.slice.primary.shopify_collection_id)) {
        return
      }

      this.refreshing = true

      try {
        const collection = await services.collectionService.get(
          this.slice.primary.shopify_collection_id,
          { pageSize: this.pageSize, filters: this.selectedFilters },
          this.$i18n.locale,
          next && this.currentEndCursor ? this.currentEndCursor : null
        )

        let products = collection.products.nodes
          .filter((p) =>
            p.metafields.some(
              (m) => m && m.namespace === 'prismic' && m.key === 'links'
            )
          )
          .map((p) => new ShopifyProduct(p))
          .filter((p) =>
            p.hasPrismicUidFromContextAndLocale(
              this.$route.params.context,
              this.$i18n.locale
            )
          )

        if (initialProducts.length > 0) {
          products = [...initialProducts, ...products]
        }

        if (products.length === 0 && !next) {
          this.products = []
          this.orderPrismicProducts()
          this.load = false
          this.init = true
          return
        }

        if (this.loadMultiplePage > this.page) {
          this.page++
          this.hasNextPage = collection.products.pageInfo.hasNextPage
          if (this.hasNextPage) {
            this.currentEndCursor = collection.products.pageInfo.endCursor
            return this.loadShopifyCollection(true, scrollToTop, products)
          }
        }

        const prismicUids = products.map(
          (p) =>
            p.prismicLinks[this.$route.params.context][
              this.$i18n.locale.slice(0, 2)
            ]
        )

        let pp = await this.$prismic.client
          .getByUIDs('product', prismicUids, {
            graphQuery: productGraphql,
            pageSize: prismicUids.length,
            lang: this.$i18n.locale,
          })
          .then((response) => {
            return response.results.map((r) => {
              const prismic = new PrismicProduct(r)
              return {
                prismic,
                shopify: products.find((p) => p.id === prismic.shopifyId),
              }
            })
          })

        pp = prismicUids
          .map((uid) => {
            return pp.find((p) => p.prismic.uid === uid)
          })
          .filter((p) => p)

        if (next && initialProducts.length === 0) {
          this.products = [...this.products, ...pp]
        } else {
          this.products = pp
        }

        this.orderPrismicProducts()

        this.hasNextPage = collection.products.pageInfo.hasNextPage
        this.currentEndCursor = collection.products.pageInfo.endCursor

        if (
          collection.products.filters &&
          collection.products.filters.length > 0
        ) {
          if (
            collection.products.filters.some(
              (f) => f.id === 'filter.p.product_type'
            )
          ) {
            if (
              !this.selectedFilters.sizes ||
              this.selectedFilters.sizes.length === 0
            ) {
              this.filters.product_type = collection.products.filters.find(
                (f) => f.id === 'filter.p.product_type'
              ).values
            }
          }

          if (
            collection.products.filters.some(
              (f) => f.id === 'filter.v.option.size'
            ) &&
            this.slice.primary.list_type !== 'agenda-de-retour-un-jour'
          ) {
            this.filters.sizes = collection.products.filters
              .find((f) => f.id === 'filter.v.option.size')
              .values.filter((s) => s.count > 0)
          } else {
            this.filters.sizes = undefined
          }
        }

        if (this.init && !next && scrollToTop) {
          if (
            this.selectedFilters &&
            Object.keys(this.selectedFilters).length > 0
          ) {
            if (
              this.$route.query.productType &&
              this.selectedFilters.productType &&
              this.$route.query.productType === this.selectedFilters.productType
            ) {
              this.$router.replace({
                query: {
                  ...this.$route.query,
                  ...this.selectedFilters,
                },
              })
            } else {
              this.$router.push({
                query: {
                  ...this.$route.query,
                  ...this.selectedFilters,
                },
              })
            }
          } else if (this.$route.query.productType) {
            this.$router.push({
              query: {
                ...this.$route.query,
                ...{ productType: undefined, sizes: undefined },
              },
            })
          }
        }

        if (collection.metafield && collection.metafield.value) {
          const d = new Date(collection.metafield.value)

          this.sellsWillOpenSoon = isFuture(d)
        } else {
          this.sellsWillOpenSoon = false
        }

        this.init = true
        this.refreshing = false

        if (scrollToTop) {
          this.$nextTick().then((_) => {
            this.scrollToTop()
          })
        }
      } catch (err) {
        this.$logError.captureException(err)
      }
    },
    async loadStockCollection() {
      if (
        process.env.SHOPIFY_STOCK_COLLECTION_UID &&
        process.env.SHOPIFY_STOCK_COLLECTION_UID[this.$route.params.context]
      ) {
        try {
          const collection = await services.collectionService.get(
            process.env.SHOPIFY_STOCK_COLLECTION_UID[
              this.$route.params.context
            ],
            { onlyDatesInMetafield: true },
            this.$i18n.locale
          )

          this.stockCollection = collection
          this.stockDates = extractMetafieldsOfCollection(collection)
        } catch (err) {
          this.$logError.captureException(err)
        }
      }
    },
    populateFromSession() {
      if (this.slice.primary.list_type === 'en-stock') {
        let savedProductsByCollection = sessionStorage.getItem(LS_PRODUCT)
        let savedOrderedProductsByCollection =
          sessionStorage.getItem(LS_ORDERED_PRODUCTS)
        let savedConfig = sessionStorage.getItem(LS_PRODUCT_CONFIG)

        if (
          savedProductsByCollection &&
          savedOrderedProductsByCollection &&
          savedConfig
        ) {
          savedProductsByCollection = JSON.parse(savedProductsByCollection)
          savedOrderedProductsByCollection = JSON.parse(
            savedOrderedProductsByCollection
          )
          savedConfig = JSON.parse(savedConfig)

          if (
            savedProductsByCollection[this.slice.id] &&
            savedOrderedProductsByCollection[this.slice.id] &&
            savedConfig[this.slice.id]
          ) {
            this.products = savedProductsByCollection[this.slice.id].map(
              (p) => ({
                prismic: PrismicProduct.fromJSON(p.prismic),
                shopify: ShopifyProduct.fromJSON(p.shopify),
                additionalInventory: p.additionalInventory,
              })
            )

            this.orderedProducts = savedOrderedProductsByCollection[
              this.slice.id
            ].map((p) => ({
              prismic: PrismicProduct.fromJSON(p.prismic),
              shopify: ShopifyProduct.fromJSON(p.shopify),
              additionalInventory: p.additionalInventory,
            }))

            this.config = savedConfig[this.slice.id]

            this.loadMultiplePage = Math.ceil(
              this.products.length / this.pageSize
            )
            return true
          }
        }
      }

      return false
    },
    saveProductInSession(products) {
      if (this.slice.primary.list_type === 'en-stock') {
        let savedProductsByCollection = sessionStorage.getItem(LS_PRODUCT)

        if (savedProductsByCollection) {
          savedProductsByCollection = JSON.parse(savedProductsByCollection)
        } else {
          savedProductsByCollection = {}
        }

        sessionStorage.setItem(
          LS_PRODUCT,
          JSON.stringify({
            ...savedProductsByCollection,
            [this.slice.id]: products,
          })
        )
      }
    },
    saveOrderedProductsInSession(orderedProducts) {
      if (this.slice.primary.list_type === 'en-stock') {
        let savedProductsByCollection =
          sessionStorage.getItem(LS_ORDERED_PRODUCTS)

        if (savedProductsByCollection) {
          savedProductsByCollection = JSON.parse(savedProductsByCollection)
        } else {
          savedProductsByCollection = {}
        }

        sessionStorage.setItem(
          LS_ORDERED_PRODUCTS,
          JSON.stringify({
            ...savedProductsByCollection,
            [this.slice.id]: orderedProducts,
          })
        )
      }
    },
    saveConfigInSession(config) {
      if (this.slice.primary.list_type === 'en-stock') {
        let savedConfigByCollection = sessionStorage.getItem(LS_PRODUCT_CONFIG)

        if (savedConfigByCollection) {
          savedConfigByCollection = JSON.parse(savedConfigByCollection)
        } else {
          savedConfigByCollection = {}
        }

        sessionStorage.setItem(
          LS_PRODUCT_CONFIG,
          JSON.stringify({
            ...savedConfigByCollection,
            [this.slice.id]: config,
          })
        )
      }
    },
    instantiateShopifyProduct(p) {
      return p instanceof ShopifyProduct ? p : ShopifyProduct.fromJSON(p)
    },
    populateDatas() {
      const isAgendaBientotDispo =
        this.slice.primary.list_type === 'agenda-bientot-dispo'

      this.products = this.products
        .filter((p) => {
          return isAgendaBientotDispo
            ? p.prismic !== undefined
            : p.prismic !== undefined && p.shopify !== undefined
        })
        .map((p) => {
          p.type = 'product'
          p.prismic =
            p.prismic instanceof PrismicProduct
              ? p.prismic
              : PrismicProduct.fromJSON(p.prismic)

          if (isAgendaBientotDispo) {
            p.shopify = p.shopify
              ? this.instantiateShopifyProduct(p.shopify)
              : new ShopifyProduct({ id: 0 })
          } else {
            p.shopify = this.instantiateShopifyProduct(p.shopify)
          }

          return p
        })

      this.orderPrismicProducts()
    },
    orderPrismicProducts() {
      let pp = []

      if (this.config && this.config.product_order.length > 0) {
        pp.push(
          ...this.config.product_order
            .map((p) => {
              if (
                process.env.ABTEST_UID_SWITCH &&
                process.env.ABTEST_UID_SWITCH.products.length > 0
              ) {
                const uidToOverride =
                  process.env.ABTEST_UID_SWITCH.products.find(
                    (op) => op.origin === p.product.uid
                  )
                if (uidToOverride) {
                  return uidToOverride.alter
                }
              }
              return p.product.uid
            })
            .filter((uid) =>
              this.products.some((product) => product.prismic.uid === uid)
            )
            .map((uid) => {
              const p = this.products.find(
                (product) => product.prismic.uid === uid
              )

              return p
            })
        )
      }

      const missingProducts = this.products.filter((product) => {
        return !pp.some((p) => p.prismic.uid === product.prismic.uid)
      })

      if (missingProducts.length > 0) {
        pp.push(...missingProducts)
      }

      if (this.config && this.config.product_exclude.length > 0) {
        pp = pp.filter((p) => {
          return !this.config.product_exclude.some(
            (e) => e.product.uid === p.prismic.uid
          )
        })
      }

      if (this.showStockCard) {
        const stockCard = {
          type: 'stock',
          prismic: { id: 'stock-card' },
          shopify: {
            ...this.stockCollection,
            dates: {
              start: this.stockDates.start_date_web,
              end: this.stockDates.end_date,
              teasing: this.stockDates.teasing_date,
            },
          },
        }

        let index = pp.findIndex(
          (p) =>
            p.prismic.dates.start.getTime() >
            this.stockDates.start_date_web.getTime()
        )

        index = index === -1 ? pp.length : index

        pp.splice(index, 0, stockCard)
      }

      if (this.origin === 'account') {
        pp.sort((a, b) => {
          // les produits onHome en premier
          if (a.prismic.onHome && !b.prismic.onHome) {
            return -1
          }

          if (!a.prismic.onHome && b.prismic.onHome) {
            return 1
          }

          return 0
        })
      }

      this.orderedProducts = pp
    },
    async refreshShopifyProducts() {
      let pp = []

      try {
        pp = await services.productService.refreshShopifyProducts(
          this.orderedProducts.map((p) => p.shopify.id),
          this.$i18n.locale
        )
      } catch (err) {
        this.$logError.captureException(err)
        // this.error.refresh = true
        return
      }

      for (const shopifyProductData of pp) {
        if (shopifyProductData.id) {
          const productListItem = this.products.find(
            (p) => p.shopify.id === ShopifyBase64.getId(shopifyProductData.id)
          )

          if (
            productListItem &&
            productListItem.shopify &&
            productListItem.shopify instanceof ShopifyProduct
          ) {
            productListItem.shopify.update(shopifyProductData)

            const index = this.orderedProducts.findIndex(
              (p) => p.shopify.id === ShopifyBase64.getId(shopifyProductData.id)
            )

            if (index !== -1) {
              this.orderedProducts.splice(index, 1, productListItem)
            }
          } else {
            this.$logError.captureException(
              new Error('Refresh shopify product fail : no product found'),
              {
                shopifyId: ShopifyBase64.getId(shopifyProductData.id),
                refreshShopifyData: JSON.stringify(shopifyProductData),
                prismicProducts: JSON.stringify(this.prismicProduct),
                shopifyProducts: JSON.stringify(this.shopifyProducts),
              }
            )
          }
        }
      }

      this.$nuxt.$emit(EVENTS.PRODUCT_UPDATED)
    },
    prepareFilters() {
      if (Object.keys(this.$route.query).length > 0) {
        if (this.$route.query.productType) {
          this.selectedFilters.productType = this.$route.query.productType
        }

        if (this.$route.query.sizes) {
          this.sizes = this.$route.query.sizes
        }
      }
    },
    onFiltersUpdate(filters, fromUser = true) {
      if (filters.productType) {
        this.selectedFilters = filters
      } else {
        this.selectedFilters = {}
      }

      this.loadShopifyCollection(false, fromUser)
    },
    onDisplayUpdate(display) {
      this.pageSize = display.pageSize
      this.listDisplay = display.listDisplay
    },
    containerComponent() {
      if (this.isASlider) {
        return Slider
      }

      return DefaultDiv
    },
    cardComponent(type) {
      if (type === 'stock') {
        return StockCard
      }

      if (this.isASlider) {
        return ProductCard
      }

      if (this.isRefCo) {
        return ProductCardRefCo
      }

      return ProductCardV2
    },
    productCardAttributes(i, product) {
      if (this.isASlider) {
        return {}
      }

      if (this.isRefCo) {
        return {
          class: 'mb-0-2',
          hideTags: this.config.hide_tags,
        }
      }

      return {
        typeMobile: this.gridItemPositions[i]
          ? this.gridItemPositions[i].mobile.type
          : '',
        typeDesktop: this.gridItemPositions[i]
          ? this.gridItemPositions[i].desktop.type
          : '',
        style: this.gridItemPositions[i]
          ? `
          --product-grid-column-mobile: ${this.gridItemPositions[i].mobile.column};
          --product-grid-column-desktop: ${this.gridItemPositions[i].desktop.column};
          --product-grid-row-mobile: ${this.gridItemPositions[i].mobile.row};
          --product-grid-row-desktop: ${this.gridItemPositions[i].desktop.row};
          `
          : '',
        stock: this.slice.primary.list_type === 'en-stock',
        home:
          this.slice.primary.list_type === 'page-accueil' &&
          !this.forceListTypeAgenda,
        small: ['1/7', '7/13'].includes(
          this.gridItemPositions[i].mobile.column
        ),
        useDefaultImage:
          this.slice.primary.list_type === 'page-accueil' ||
          prismic.isFilled.image(product.prismic.eshopImage),
      }
    },
    getNumberOfLines(matrix) {
      let index = 0
      let currentLine = 0
      let numberOfLines = 0

      while (index < this.orderedProducts.length) {
        switch (matrix[currentLine].ligne) {
          case NUMBER_BY_LINE.ONE_PER_LINE:
            index += 1
            break
          case NUMBER_BY_LINE.TWO_PER_LINE:
            index += 2
            break
          case NUMBER_BY_LINE.THREE_PER_LINE:
            index += 3
            break
        }
        numberOfLines += 1
        currentLine += 1

        if (currentLine >= matrix.length) {
          currentLine = 0
        }
      }

      return numberOfLines
    },
    getGridLines(matrix) {
      let index = 0
      let currentLine = 0
      const gridLines = []

      while (index < this.orderedProducts.length) {
        switch (matrix[currentLine].ligne) {
          case NUMBER_BY_LINE.ONE_PER_LINE:
            index += 1
            gridLines.push(1)
            break
          case NUMBER_BY_LINE.TWO_PER_LINE:
            if (index + 1 >= this.orderedProducts.length) {
              index += 1
              gridLines.push(1)
              break
            }

            index += 2
            gridLines.push(2)
            break
          case NUMBER_BY_LINE.THREE_PER_LINE:
            index += 3
            gridLines.push(3)
            break
        }
        currentLine += 1

        if (currentLine >= matrix.length) {
          currentLine = 0
        }
      }

      return gridLines
    },
    getGridItemsPositions() {
      const gridItemsPositions = []
      let index = 0
      let row = 1
      let rowMobile = 1
      let column = 1
      let columnMobile = 1
      let currentLine = 0
      let currentMobileLine = 0

      while (index < this.orderedProducts.length) {
        const line = { desktop: {}, mobile: {} }
        switch (this.currentGrid.desktop[currentLine].ligne) {
          case NUMBER_BY_LINE.ONE_PER_LINE:
            line.desktop = {
              column: '1/13',
              row: `${row}/${row + 1}`,
              type: 'one-per-line',
            }

            currentLine += 1
            row += 1
            break

          case NUMBER_BY_LINE.TWO_PER_LINE:
            if (index + 1 >= this.orderedProducts.length && column === 1) {
              line.desktop = {
                column: '1/13',
                row: `${row}/${row + 1}`,
                type: 'one-per-line',
              }

              currentLine += 1
              row += 1
              break
            }

            line.desktop = {
              column: `${column}/${column + 6}`,
              row: `${row}/${row + 1}`,
              type: 'two-per-line',
            }

            if (column > 6) {
              column = 1
              row += 1
              currentLine += 1
            } else {
              column += 6
            }
            break

          case NUMBER_BY_LINE.THREE_PER_LINE:
            if (index + 1 >= this.orderedProducts.length && column === 1) {
              line.desktop = {
                column: '1/13',
                row: `${row}/${row + 1}`,
                type: 'one-per-line',
              }

              currentLine += 1
              row += 1
              break
            } else if (
              index + 2 >= this.orderedProducts.length &&
              column === 1
            ) {
              line.desktop = {
                column: `${column}/${column + 6}`,
                row: `${row}/${row + 1}`,
                type: 'two-per-line',
              }

              column += 6

              break
            } else if (
              index + 1 >= this.orderedProducts.length &&
              column === 7
            ) {
              line.desktop = {
                column: `${column}/${column + 6}`,
                row: `${row}/${row + 1}`,
                type: 'two-per-line',
              }

              column = 1
              row += 1
              currentLine += 1

              break
            }

            line.desktop = {
              column: `${column}/${column + 4}`,
              row: `${row}/${row + 1}`,
              type: 'three-per-line',
            }

            if (column > 8) {
              column = 1
              row += 1
              currentLine += 1
            } else {
              column += 4
            }
            break
        }

        switch (this.currentGrid.mobile[currentMobileLine].ligne) {
          case NUMBER_BY_LINE.ONE_PER_LINE:
            line.mobile = {
              column: '1/13',
              row: `${rowMobile}/${rowMobile + 1}`,
              type: 'one-per-line',
            }

            currentMobileLine += 1
            rowMobile += 1
            break

          case NUMBER_BY_LINE.TWO_PER_LINE:
            if (
              index + 1 >= this.orderedProducts.length &&
              columnMobile === 1
            ) {
              line.mobile = {
                column: '1/13',
                row: `${rowMobile}/${rowMobile + 1}`,
                type: 'one-per-line',
              }

              currentMobileLine += 1
              rowMobile += 1
              break
            }

            line.mobile = {
              column: `${columnMobile}/${columnMobile + 6}`,
              row: `${rowMobile}/${rowMobile + 1}`,
              type: 'two-per-line',
            }

            if (columnMobile > 6) {
              columnMobile = 1
              rowMobile += 1
              currentMobileLine += 1
            } else {
              columnMobile += 6
            }
            break
        }

        gridItemsPositions.push(line)

        if (currentLine >= this.currentGrid.desktop.length) {
          currentLine = 0
        }

        if (currentMobileLine >= this.currentGrid.mobile.length) {
          currentMobileLine = 0
        }

        index++
      }

      return gridItemsPositions
    },
    sliceIn() {
      if (!this.hasCheckIntegrity) {
        if (
          this.slice.primary.list_type !== 'agenda-de-retour-un-jour' &&
          this.slice.primary.list_type !== 'page-accueil' &&
          this.slice.primary.list_type !== 'en-stock'
        ) {
          this.checkIntegrity()
        }
      }

      this.$emit('in')
    },
    async scrollToTop() {
      if (!this.$refs.gridOfProducts) {
        return
      }

      const { gsap } = await import('gsap' /* webpackChunkName: 'gsap' */)
      const { ScrollToPlugin } = await import(
        'gsap/ScrollToPlugin' /* webpackChunkName: 'gsap_scroll_to_plugin' */
      )
      gsap.registerPlugin(ScrollToPlugin)

      let offsetY = 0

      if (document.body.classList.contains('header-visible')) {
        offsetY += document.getElementById('header').firstChild.offsetHeight
      }

      this.setScroll(true)
      gsap.to(window, {
        duration: 0.5,
        scrollTo: {
          y: this.$refs.gridOfProducts.$el || this.$refs.gridOfProducts,
          offsetY,
        },
        onComplete: () => {
          this.setScroll(false)
        },
      })
    },
    onWaypoint(waypoint) {
      if (
        waypoint.going === this.$waypointMap.GOING_IN &&
        !this.load &&
        !this.refreshing
      ) {
        if (
          this.slice.primary.list_type === 'en-stock' ||
          this.slice.primary.list_type === 'agenda-de-retour-un-jour'
        ) {
          this.loadShopifyCollection(true)
        } else {
          this.loadProducts()
        }
      }
    },
    reduceFilters() {
      this.hideFilters = !this.hideFilters
    },
    onFilterOpened() {
      this.openedFilters = true
    },
    onFilterClosed() {
      this.openedFilters = false
    },
  },
}
</script>

<style lang="scss">
.slice--products {
  overflow: hidden;
  max-width: 100vw;

  .slider {
    @include mq($from: tablet, $until: large) {
      --gutter: calc(100% / 12);
    }
  }

  .slider-nav--as-square .action {
    margin-top: rem(-32px);
  }

  .load-more {
    height: 75svh;
  }

  &.slice--products--with-filters {
    overflow: visible;
  }

  &.slice--products--opened-filter {
    margin: 0 !important;
  }
}

.create-alert--card {
  .inner {
    padding: calc(var(--spacing) * 3) calc(var(--spacing) * 2);
    background-color: var(--secondary-color-4);
    overflow: hidden;

    @include mq($from: tablet) {
      min-height: rem(470px);
    }

    @include mq($until: tablet) {
      --input-height: #{rem(50px)};
    }

    div {
      position: relative;
      z-index: 2;
    }
  }

  .icon--bg {
    position: absolute;
    width: auto;
    height: 100%;
    right: rem(-75px);
    bottom: 0;
    z-index: 1;
    pointer-events: none;
    opacity: 0.05;
  }

  form {
    flex-shrink: 0;

    > div {
      display: block;
    }
  }

  .action {
    margin: 0;
    width: 100%;
    height: var(--input-height);
  }

  &--0 {
    @include mq($from: tablet) {
      .h4 {
        margin-bottom: 0;
        color: var(--tertiary-color);
        padding-right: calc(var(--spacing) * 4);
        flex-grow: 1;
      }

      .inner {
        --input-height: #{rem(50px)};

        min-height: 0;
        background-color: var(--secondary-color);
        padding: calc(var(--spacing) * 2) calc(var(--spacing) * 3);

        > div {
          display: flex;
          align-items: center;
        }
      }

      .input {
        width: rem(300px);
        margin-bottom: 0;
      }

      form {
        > div {
          display: flex;
          align-items: center;
        }

        .action {
          margin-left: calc(var(--spacing) * 2);
        }
      }

      .action {
        width: auto;
      }
    }
  }
}

.grid-of-products {
  --default-grid-height-one-per-line: calc(100vw * 790 / 480);
  --default-grid-height-two-per-line: calc((50vw - #{3px}) * 790 / 480);

  grid-template-columns: repeat(12, 1fr);
  grid-gap: calc(var(--spacing) * 0.2);
  grid-template-rows: repeat(
    var(--number-of-lines),
    var(--default-grid-height-one-per-line)
  );
  display: grid;

  @include mq($from: tablet) {
    grid-template-rows: repeat(var(--number-of-lines), minmax(93vh, 600px));
  }

  @include mq($from: large) {
    grid-template-rows: repeat(var(--number-of-lines), minmax(790px, 93vh));
  }

  > div {
    grid-column: var(--product-grid-column-mobile);
    grid-row: var(--product-grid-row-mobile);

    @include mq($from: tablet) {
      grid-column: var(--product-grid-column-desktop);
      grid-row: var(--product-grid-row-desktop);
    }
  }

  .slice--products--grid--home & {
    grid-template-rows: repeat(var(--number-of-lines), 100svh);
  }
}

.grid-filter {
  @include mq($from: tablet) {
    --filters-width: #{rem(260px)};

    display: grid;
    transition: grid-template-columns 0.6s var(--asphalte-animation-function);
    grid-template-columns: var(--filters-width) 1fr;

    .list__filter {
      width: var(--filters-width);
      transition: all 0.5s var(--asphalte-animation-function) 0.2s;
    }
  }

  @include mq($from: tablet) {
    &.hide-filters {
      grid-template-columns: 0 1fr;
      transition: grid-template-columns 0.6s var(--asphalte-animation-function)
        0.2s;

      .list__filter {
        opacity: 0;
        transform: translate(-2rem, 0);
        transition: all 0.5s var(--asphalte-animation-function);
      }
    }
  }

  .grid-of-products {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
  }

  &__filters {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }

  &__refreshing {
    position: relative;
    grid-column: 1/-1;
    grid-row: 1/-1;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: rgba($tertiary-color, 0.5);
      z-index: 250;
      pointer-events: all;
      cursor: progress;
    }

    &__inner {
      position: absolute;
      top: calc(var(--spacing) * 2);
      left: 0;
      right: 0;
      z-index: 251;
      display: flex;
      align-items: center;
      justify-content: center;

      .loader {
        --loader-width: #{rem(32px)};
      }
    }

    &.filter-refresh-enter-active,
    &.filter-refresh-leave-active {
      transition: all 0.3s;

      &::before,
      .grid-of-products__refreshing__inner {
        transition: all 0.3s;
      }
    }

    &.filter-refresh-enter,
    &.filter-refresh-leave-to {
      &::before,
      .grid-of-products__refreshing__inner {
        opacity: 0;
      }

      .grid-of-products__refreshing__inner {
        transform: translateY(-10px);
      }
    }
  }
}

.open-filters {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 10;
  transition: all 0.3s var(--asphalte-animation-function);

  @include mq($until: tablet) {
    display: none;
  }

  .btn-link {
    background-color: $tertiary-color;
    padding: calc(var(--spacing) * 0.6) var(--spacing)
      calc(var(--spacing) * 0.8);
    transition: all 0.3s var(--asphalte-animation-function);
    color: var(--secondary-color);

    @include on-hover-and-focus {
      padding: calc(var(--spacing) * 0.6) calc(var(--spacing) * 1.5)
        calc(var(--spacing) * 0.8);
      background-color: rgba($tertiary-color, 1);
      color: var(--secondary-color);
    }
  }

  .link--underline > span::after {
    bottom: rem(-2px);
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
    transition: all 0.3s var(--asphalte-animation-function) 0.7s;
  }

  .is-affixed {
    .open-filters__inner {
      transition: padding 0.4s cubic-bezier(0.46, 0.01, 0.32, 1);
      width: auto !important; // SURCHARGE STICKY

      @include mq($from: tablet) {
        .header-visible & {
          padding-top: calc(var(--header-height));
        }
      }
    }
  }
}

@keyframes appear-left {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
