<template>
  <div
    class="product-card-v2"
    :class="cardClass"
    @mouseenter="$vssWidth > 769 ? (hovering = true) : false"
    @mouseleave=";(hovering = false), (activeColor = -1)"
  >
    <component
      :is="mainComponent()"
      :to="to"
      @click.native="!isLink ? null : (loading = true)"
    >
      <div class="product-card-v2__image">
        <transition mode="in-out" name="product-card-image-switch">
          <lazy-image
            v-if="images.length > 0"
            :key="currentColor ? currentColor.color_name : 'default'"
            :images="images"
            :preload="isFirst"
            :force-show="isFirst"
          />
        </transition>
      </div>
      <div class="product-card-v2__content text-color-tertiary">
        <div class="inner">
          <div v-if="home && !isSaisonnier" class="d-none d-md-block">
            <p class="lh-1 text-md-base text-left mb-0-8">
              <span
                v-if="
                  prismicProduct.marketing.type === 'Texte' &&
                  prismicProduct.marketing.message
                "
              >
                {{ prismicProduct.marketing.message }}
              </span>
              <span v-else>
                {{ $t('preco_of_the_week') }}
              </span>
            </p>
          </div>
          <div v-else-if="isAgenda && isFirst" class="d-none d-md-block">
            <p
              v-if="prismicProduct.dates.start"
              class="lh-1 text-md-base text-left mb-0-8"
            >
              {{
                $t('lauch_day', {
                  day: $d(prismicProduct.dates.start, 'weekday'),
                })
              }}
            </p>
          </div>
          <div
            v-else-if="
              (!isAgenda || prismicProduct.dates.start) && !stock && !isGiftCard
            "
            class="d-flex mb-1"
            :class="{
              'justify-center': !home && !isFirst,
            }"
          >
            <div>
              <marketing-argument
                :marketing="prismicProduct.marketing"
                :dates="prismicProduct.dates"
                :inventory="productInventory"
                :status="prismicProduct.status"
              />
            </div>
          </div>

          <p
            class="product-card-v2__name text-secondary mb-0-2"
            :class="{
              'text-center': !home && !isFirst && !soonInSell && !stock,
            }"
          >
            {{ prismicProduct.name }}
          </p>

          <div
            v-if="!home && !isAgenda && !isGiftCard"
            class="product-card-v2__price d-flex flex-wrap align-baseline mb-1 mt-0-6"
          >
            <div
              class="product-card__price d-flex align-center mb-0 flex-shrink-0"
              :class="{ 'mr-1 mr-md-2': distinctColors.length > 1 }"
            >
              <span v-if="!shopifyProduct" class="d-block">
                <strong
                  class="skeleton skeleton--bar d-inline-block mr-0-6"
                  style="width: 30px; height: 16px"
                ></strong>
                <strong
                  class="skeleton skeleton--bar d-inline-block"
                  style="width: 30px; height: 16px"
                ></strong>
              </span>
              <div v-else class="product-card__price__inner d-block">
                <p
                  class="price d-flex flex-wrap align-baseline text-color-tertiary text-secondary mb-0 mr-0-6 mr-md-1"
                >
                  <span class="number pr-0-4"
                    >{{ shopifyProduct.price }}&nbsp;&euro;</span
                  >

                  <span
                    class="product-card__price__text text-small text-no-wrap"
                    >{{
                      $t(
                        showCompareAtPrice
                          ? 'product.in_preco'
                          : 'product.in_stock'
                      )
                    }}</span
                  >
                </p>

                <p
                  v-if="showCompareAtPrice"
                  class="price compare-at-price d-flex flex-wrap align-baseline text-color-tertiary text-secondary mb-0 mr-0-6 mr-md-1"
                >
                  <span class="number pr-0-4"
                    >{{ shopifyProduct.compareAtPrice }}&nbsp;&euro;</span
                  >
                  <span
                    class="product-card__price__text text-small text-no-wrap"
                    >{{ $t('product.in_stock') }}</span
                  >
                </p>
              </div>
            </div>

            <transition
              v-if="distinctColors.length > 1"
              mode="out-in"
              @enter="colorEnter"
              @leave="colorLeave"
            >
              <div
                v-if="hovering"
                class="product-card__colors d-flex align-center flex-wrap"
              >
                <button
                  v-for="(color, index) in distinctColors"
                  :key="color.name"
                  type="button"
                  class="colors__matter"
                  :class="{ active: activeColor === index }"
                  :title="color.color_name"
                  @mouseenter="colorMatterMouseEnter(index)"
                  @click.prevent="goToColor(color.color_name)"
                >
                  <span
                    class="bg"
                    :style="`background-image: url(${getColorThumbnailBg(
                      color
                    )})`"
                  ></span>
                  <span class="visually-hidden">{{ color.color_name }}</span>
                </button>
              </div>
              <p
                v-else
                class="d-none d-md-block product-card__colors--number mb-0"
              >
                <span v-if="small">
                  <span class="d-inline d-md-none">
                    {{
                      $t('card.product.number-of-colors-small', {
                        number: distinctColors.length,
                      })
                    }}
                  </span>
                  <span class="d-none d-md-inline">
                    {{
                      $t('card.product.number-of-colors', {
                        number: distinctColors.length,
                      })
                    }}
                  </span>
                </span>
                <span v-else>
                  {{
                    $t('card.product.number-of-colors', {
                      number: distinctColors.length,
                    })
                  }}
                </span>
              </p>
            </transition>
          </div>
          <div
            v-else-if="!isLink"
            :class="{
              'pb-0-4 mb-1': !isFirst,
              'w-100 pb-md-0-4 mb-0-4 mb-md-1': isFirst,
            }"
          >
            <div v-if="isFirst">
              <p class="d-none d-md-block lh-1-2 pb-md-0-4">
                <span class="d-block">{{
                  $t('card.product.agenda.alert.1')
                }}</span>
                <span class="d-block">{{
                  $t('card.product.agenda.alert.2')
                }}</span>
              </p>

              <div class="d-flex align-center justify-center d-md-none">
                <p class="text-small lh-1-2 mb-0 pr-1 pt-0-4">
                  {{
                    $tc(
                      'card.product.number-of-colors-soon',
                      distinctColors.length,
                      {
                        number: distinctColors.length,
                      }
                    )
                  }}
                </p>

                <div class="d-flex justify-center pt-0-4">
                  <button
                    class="product-card-v2__agenda text-color-tertiary w-600"
                    @click.prevent="onAlertClick($event)"
                  >
                    <span class="d-flex align-center link--underline">
                      <icon name="bell" :full="isSubscribed" />
                      <span class="d-block ml-0-4">{{
                        $t(
                          isSubscribed
                            ? 'alert.tooltip.remove'
                            : 'alert.tooltip.create'
                        )
                      }}</span>
                    </span>
                  </button>
                </div>
              </div>
              <div v-if="$vssWidth > 796" class="d-none d-md-block">
                <create-alert
                  :product="{
                    productSlug: prismicProduct.uid,
                    productName: prismicProduct.name,
                    productId: shopifyProduct.id,
                  }"
                  :origin="origin"
                  button-size="primary"
                  inline
                  @has-subscribe="hasSubscribe()"
                  @has-unsubscribe="hasUnSubscribe()"
                />
              </div>
            </div>
            <div v-else>
              <p v-if="!bisOnly" class="text-small text-center lh-1-2 pt-0-2">
                {{
                  $tc(
                    'card.product.number-of-colors-soon',
                    distinctColors.length,
                    {
                      number: distinctColors.length,
                    }
                  )
                }}
              </p>
              <div
                class="d-flex justify-center pt-0-4"
                :class="{
                  'mt-0-6': distinctColors.length === 0,
                }"
              >
                <button
                  class="product-card-v2__agenda text-color-tertiary w-600"
                  @click.prevent="onAlertClick($event)"
                >
                  <span
                    v-if="bisOnly"
                    class="d-flex align-center link--underline"
                  >
                    <icon :name="isSubscribed ? 'heart-full' : 'heart'" />
                    <span class="d-block ml-0-4">{{
                      $t(
                        isSubscribed
                          ? 'alert.tooltip.unlove'
                          : 'alert.tooltip.love'
                      )
                    }}</span>
                  </span>
                  <span v-else class="d-flex align-center link--underline">
                    <icon name="bell" :full="isSubscribed" />
                    <span class="d-block ml-0-4">{{
                      $t(
                        isSubscribed
                          ? 'alert.tooltip.remove'
                          : 'alert.tooltip.create'
                      )
                    }}</span>
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div v-if="isGiftCard">
            <p
              class="product-card__price d-flex justify-center align-center w-500 mb-0 flex-shrink-0"
            >
              <span v-if="!shopifyProduct" class="d-block">
                <strong
                  class="skeleton skeleton--bar d-inline-block mr-0-6"
                  style="width: 30px; height: 16px"
                ></strong>
                <strong
                  class="skeleton skeleton--bar d-inline-block"
                  style="width: 30px; height: 16px"
                ></strong>
              </span>
              <span v-else class="d-block">
                <strong>{{
                  $t('product.price_range', {
                    min: shopifyProduct.priceRange.min,
                    max: shopifyProduct.priceRange.max,
                  })
                }}</strong>
              </span>
            </p>
          </div>

          <div
            v-if="isLink && !stock && prismicProduct.buttonLabel"
            class="mt-1 mb-0-6"
            :class="{
              'd-flex justify-center': !isFirst,
              'd-none d-md-flex justify-start': isFirst && !isEternal,
              'd-flex justify-center justify-md-start': isFirst && isEternal,
            }"
          >
            <primary-button
              ref="link"
              type="button"
              :text="prismicProduct.buttonLabel"
              with-arrow
            />
          </div>

          <!-- <div v-if="stock && availableSizes.length > 0">
            <p class="text-color-secondary-4 mt-0-6 mb-0-2">
              {{ $t('product.available_sizes') }}
            </p>

            <div
              class="product-card__sizes"
              :class="{
                refresh,
                'justify-md-end': isTextPositionLeft,
              }"
            >
              <div
                v-for="(size, index) in availableSizes"
                :key="index"
                class="product-card__size"
                :class="{
                  'unavailable w-400': !size.available,
                  'w-600': size.available,
                }"
              >
                <span class="text-uppercase">{{ size.label }}</span>
              </div>
            </div>
          </div> -->
        </div>

        <div
          v-if="(home || isAgenda) && isFirst && !isEternal"
          class="product-card-v2__countdown d-flex d-md-block flex-direction-column align-center mt-1 mt-md-0"
        >
          <div
            v-if="home || isAgenda"
            class="d-flex justify-center justify-md-end flex-grow-1 mb-0-6"
          >
            <div v-if="home" class="tag tag--sun-and-white">
              <icon name="estimated-time" />
              <span>{{ $t('product.tag.last_before_end') }}</span>
            </div>
            <div v-else class="tag tag--yellow-and-secondary">
              <icon name="calendar-2" />
              <span>{{ $t('product.tag.soon_preco') }}</span>
            </div>
          </div>

          <big-count-down
            v-if="home && prismicProduct.dates.end"
            :date="prismicProduct.dates.end"
            home
          />
          <big-count-down
            v-if="isAgenda && prismicProduct.dates.start"
            :date="prismicProduct.dates.start"
          />
        </div>
      </div>
    </component>
    <transition name="appear-top">
      <p v-if="subscribeError" class="text-color-error text-small text-right">
        {{ $t('product.alert.error') }}
      </p>
    </transition>

    <transition name="from-bottom" @after-enter="afterCreateAlertEnter">
      <create-alert
        v-if="isAlertOpen"
        :id="`alert-${prismicProduct.uid}`"
        :product="{
          productSlug: prismicProduct.uid,
          productName: prismicProduct.name,
          productId: shopifyProduct.id,
        }"
        :title="
          prismicProduct.dates.start !== null
            ? $t('product.alert.highlight_title', {
                name: prismicProduct.name,
                date: $d(prismicProduct.dates.start, 'short'),
              })
            : $t('product.back-one-day')
        "
        :text="
          $t(
            shopifyProduct.price === shopifyProduct.compareAtPrice
              ? 'product.receive-alert-stock'
              : 'product.receive-alert-preorder'
          )
        "
        :origin="origin"
        button-size="primary"
        with-close
        @has-subscribe="hasSubscribe()"
        @has-unsubscribe="hasUnSubscribe()"
        @close="isAlertOpen = false"
      />
    </transition>

    <transition name="from-bottom">
      <div
        v-if="loading"
        class="d-flex align-center justify-center pb-1 pt-1 text-color-tertiary text-secondary product-card-v2__loading"
      >
        <span>{{ $t('loading') }}</span>
        <icon-loading class="ml-1" />
      </div>
    </transition>
  </div>
</template>

<script>
import NuxtSSRScreenSize from 'nuxt-ssr-screen-size'
import { mapGetters, mapActions } from 'vuex'
import * as prismic from '@prismicio/client'

import LazyImage from '@/components/Image/LazyImage'
import MarketingArgument from '@/components/Product/MarketingArgument.v2'
import Icon from '@/components/icons/Icon'
import IconLoading from '@/components/icons/Loading'
import PrimaryButton from '@/components/buttons/Primary'
import DefaultDiv from '@/components/div/Default'

import { ORIGIN, STATUS, TYPES, MARKET_TYPES } from '@/const'
import Product from '@/entities/shopify/Product'
import { EVENTS } from '@/const/events'
import normalize, { normalizeUrl } from '@/utils/normalize'
import { PRODUCT_TEXT_ALIGN } from '@/entities/prismic/Product'
import { fromPrismicImageConfToBunnyImageLink } from '@/utils/imageSize'

export default {
  name: 'ProductCard',
  components: {
    LazyImage,
    MarketingArgument,
    Icon,
    IconLoading,
    PrimaryButton,
    CreateAlert: () =>
      import(
        '@/components/Product/CreateAlert' /* webpackChunkName: 'product_create_alert' */
      ),
    BigCountDown: () =>
      import(
        '@/components/countdown/BigCountDown' /* webpackChunkName: 'big_countdown' */
      ),
  },
  mixins: [NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],

  props: {
    prismicProduct: {
      type: Object,
      required: true,
    },
    shopifyProduct: {
      type: Object,
      default: null,
    },
    origin: {
      type: String,
      default: ORIGIN.AGENDA,
    },
    cardType: {
      type: String,
      default: 'grid',
    },
    typeMobile: {
      type: String,
      default: 'one-per-line',
    },
    typeDesktop: {
      type: String,
      default: 'one-per-line',
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    additionalInventory: {
      type: Number,
      default: 0,
    },
    home: { type: Boolean, default: false },
    stock: { type: Boolean, default: false },
    bisOnly: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    soonInSell: { type: Boolean, default: false },
    useDefaultImage: { type: Boolean, default: false },
  },
  data() {
    return {
      activeColor: -1,
      hovering: false,
      isAlertOpen: false,
      isSubscribed: false,
      subscribeError: false,
      loading: false,
      refresh: false,
      PRODUCT_TEXT_ALIGN,
    }
  },
  head() {
    if (this.isFirst && this.prismicProduct.isTopOfImageLight) {
      return {
        htmlAttrs: {
          class: ['header--dark'],
        },
      }
    }
    return {}
  },
  computed: {
    ...mapGetters({
      connectedEmail: 'subscriptions/connectedEmail',
      account: 'subscriptions/account',
      isSubscriptionsLoading: 'subscriptions/isLoading',
    }),
    // AB TEST Une ref/co
    isVersionB() {
      return process.env.SITE_VERSION === 'version-b'
    },
    cardClass() {
      if (this.cardType === 'slider') {
        return 'product-card-v2--slider'
      }
      return {
        'is-first': this.isFirst,
        'product-card-v2--grid': this.cardType === 'grid',
        'product-card-v2--agenda': this.isAgenda,
        'product-card-v2--preco': this.isPreco,
        'product-card-v2--home': this.home,
        'product-card-v2--stock': this.stock,
        'product-card-v2--full': this.typeDesktop === 'one-per-line',
        'product-card-v2--small': this.typeDesktop === 'three-per-line',
        'product-card-v2--gift-card': this.isGiftCard,
        'product-card-v2--soon-in-sell': this.soonInSell,
        'product-card-v2--link': this.isLink,
      }
    },
    isGiftCard() {
      return this.prismicProduct.type === TYPES.GIFTCARD
    },
    isAgenda() {
      return this.prismicProduct.status === STATUS.AGENDA && !this.stock
    },
    isSaisonnier() {
      return this.prismicProduct.tags.includes('Saisonnier')
    },
    isPreco() {
      return this.prismicProduct.status === STATUS.PRECOMMANDE && !this.stock
    },
    isLink() {
      if (this.soonInSell || this.bisOnly) {
        return false
      }

      if (!this.isAgenda) {
        return true
      }

      if (
        this.prismicProduct.dates.teasing &&
        prismic.isFilled.contentRelationship(this.prismicProduct.teasingLink)
      ) {
        return this.prismicProduct.dates.teasing < new Date()
      }

      return false
    },
    to() {
      if (
        this.isAgenda &&
        prismic.isFilled.contentRelationship(this.prismicProduct.teasingLink)
      ) {
        return this.$contextPrismicPath(this.prismicProduct.teasingLink)
      }

      return this.$contextPrismicPath({
        type: 'product',
        uid: this.prismicProduct.uid,
      })
    },
    isEternal() {
      return this.prismicProduct.marketType === MARKET_TYPES.ETERNEL
    },
    isTextPositionLeft() {
      if (this.useDefaultImage) {
        if (this.home) {
          return !this.prismicProduct.imageTextPosition
        }

        return !this.prismicProduct.imageEshopTextPosition
      }

      return false
    },
    textAlign() {
      if (this.useDefaultImage) {
        if (this.home) {
          return this.prismicProduct.imageTextAlign
        }
      }

      return null
    },
    currentColor() {
      if (
        this.prismicProduct.colors.length > 0 &&
        this.prismicProduct.colors[this.activeColor]
      ) {
        return this.prismicProduct.colors[this.activeColor]
      }
      return null
    },
    currentImage() {
      if (this.currentColor) {
        return this.currentColor.image_principale
      }

      return this.prismicProduct.image
    },
    currentImageCDN() {
      if (this.currentColor) {
        return this.currentColor.image_principale_cdn
      }

      return this.prismicProduct.imageCdn
    },
    images() {
      if (this.currentImage) {
        const imgs = []

        // MOBILE
        if (this.currentImage.list && this.currentImage.list.url) {
          imgs.push({
            image: this.currentImage.list,
            imageCdn: this.currentImageCDN,
            widths: [380, 570, 760],
            media: {
              type: 'max',
              value: 769,
            },
          })
        }

        // DESKTOP
        if (this.cardType === 'slider') {
          imgs.push({
            image: this.currentImage.list,
            imageCdn: this.currentImageCDN,
            media: {
              type: 'min',
              value: 770,
            },
            widths: [380, 570, 760],
            sizes: '380px',
          })
        } else if (
          this.typeDesktop === 'one-per-line' &&
          this.currentImage.highlight &&
          this.currentImage.highlight.url
        ) {
          imgs.push({
            image: this.currentImage.highlight,
            imageCdn: this.currentImageCDN,
            media: {
              type: 'min',
              value: 770,
            },
            widths: [1440, 2160, 2880],
            sizes: '100vw',
          })
        } else if (
          this.typeDesktop === 'two-per-line' &&
          this.currentImage.half &&
          this.currentImage.half.url
        ) {
          imgs.push({
            image: this.currentImage.half,
            imageCdn: this.currentImageCDN,
            media: {
              type: 'min',
              value: 770,
            },
            widths: [720, 1080, 1440],
            sizes: '50vw',
          })
        } else if (
          this.typeDesktop === 'three-per-line' &&
          this.currentImage.list &&
          this.currentImage.list.url
        ) {
          imgs.push({
            image: this.currentImage.list,
            imageCdn: this.currentImageCDN,
            media: {
              type: 'min',
              value: 770,
            },
            widths: [480, 720, 960],
            sizes: '33vw',
          })
        }

        return imgs
      }

      return []
    },
    showCompareAtPrice() {
      return this.shopifyProduct && !Product.willBeInStock(this.shopifyProduct)
    },
    distinctColors() {
      if (
        this.isAgenda &&
        this.shopifyProduct &&
        this.shopifyProduct.productDimensions &&
        this.shopifyProduct.productDimensions.color
      ) {
        return this.shopifyProduct.productDimensions.color
      }

      if (this.thirdDimension === null) {
        return this.prismicProduct.colors
      }

      const cc = []

      for (const color of this.prismicProduct.colors) {
        if (!cc.some((c) => c.color_name === color.color_name)) {
          cc.push(color)
        }
      }

      return cc
    },
    productInventory() {
      if (this.shopifyProduct) {
        return (
          Math.abs(this.shopifyProduct.totalInventory) +
          this.prismicProduct.stockInitial +
          this.additionalInventory
        )
      }

      return 0
    },
    // availableSizes() {
    //   if (this.prismicProduct.type === TYPES.GIFTCARD) {
    //     return []
    //   }

    //   if (
    //     (this.prismicProduct.status === STATUS.STOCK || this.soonInSell) &&
    //     this.shopifyProduct
    //   ) {
    //     return this.shopifyProduct.productDimensions.size.map((s) => {
    //       return {
    //         label: s,
    //         available:
    //           this.shopifyProduct.isDimensionAvailable &&
    //           this.shopifyProduct.isDimensionAvailable('size', s),
    //       }
    //     })
    //   }
    //   return []
    // },
  },
  beforeDestroy() {
    this.$nuxt.$off(EVENTS.USER_STATE_LOADED)

    if (this.stock) {
      this.$nuxt.$off(EVENTS.PRODUCT_UPDATED)
    }
  },
  mounted() {
    if (this.prismicProduct.status === STATUS.AGENDA) {
      if (
        this.connectedEmail === null ||
        (this.connectedEmail !== '' && this.account.email === null)
      ) {
        this.$nuxt.$on(EVENTS.USER_STATE_LOADED, (_) => {
          this.isSubscribed = this.account.isSubscribed(this.prismicProduct.uid)
        })
      } else {
        this.isSubscribed = this.account.isSubscribed(this.prismicProduct.uid)
      }
    }

    if (this.stock) {
      this.$nuxt.$on(EVENTS.PRODUCT_UPDATED, () => {
        this.refresh = true
      })
    }
  },
  methods: {
    ...mapActions({
      subscribe: 'subscriptions/subscribe',
      unsubscribe: 'subscriptions/unsubscribe',
    }),

    mainComponent() {
      if (!this.isLink) {
        return DefaultDiv
      }

      return 'nuxt-link'
    },

    getSourceSet(url, type) {
      const src = []

      const img = new URL(url)

      img.searchParams.set('cs', 'srgb')

      if (type === 'desktop') {
        switch (this.typeDesktop) {
          case 'one-per-line':
            img.searchParams.delete('rect')
            img.searchParams.set('fit', 'crop')
            img.searchParams.set('crop', 'faces')
            img.searchParams.set('w', 1440)
            img.searchParams.set('h', 790)
            break

          case 'two-per-line':
            img.searchParams.delete('rect')
            img.searchParams.set('fit', 'crop')
            img.searchParams.set('crop', 'faces')
            img.searchParams.set('w', 720)
            img.searchParams.set('h', 790)
            break

          case 'three-per-line':
            img.searchParams.set('w', 480)
            img.searchParams.set('h', 790)
            break
        }

        src.push(`${img.toString()} 1440w`)

        img.searchParams.set('dpr', 1.5)
        src.push(`${img.toString()} 2160w`)

        img.searchParams.set('dpr', 2)
        src.push(`${img.toString()} 2880w`)

        img.searchParams.set('dpr', 2.5)
        src.push(`${img.toString()} 3600w`)
      }

      return src.join(', ')
    },
    colorMatterMouseEnter(index) {
      this.activeColor = index
    },
    async colorEnter(el, done) {
      const { gsap } = await import('gsap' /* webpackChunkName: 'gsap' */)

      if (el.classList.contains('product-card__colors')) {
        const buttons = el.getElementsByClassName('colors__matter')

        gsap.from(buttons, {
          opacity: 0,
          x: 20,
          stagger: {
            ease: 'power1.out',
            amount: 0.2,
          },
          duration: 0.4,
          onComplete() {
            return done()
          },
        })
      } else {
        return gsap.from(el, {
          opacity: 0,
          duration: 0.3,
          onComplete() {
            return done()
          },
        })
      }
    },
    async colorLeave(el, done) {
      const { gsap } = await import('gsap' /* webpackChunkName: 'gsap' */)

      return gsap.to(el, {
        opacity: 0,
        duration: 0.3,
        onComplete() {
          return done()
        },
      })
    },
    goToColor(color) {
      if (this.soonInSell) {
        return
      }

      this.loading = true
      this.$router.push({
        path: this.$contextPrismicPath({
          type: 'product',
          uid: this.prismicProduct.uid,
        }),
        query: { ...this.params, color: normalize(color) },
      })
    },

    getColorThumbnailBg(color) {
      if (color.color_thumbnail_cdn) {
        return normalizeUrl(
          fromPrismicImageConfToBunnyImageLink(
            color.color_thumbnail_cdn,
            color.color_thumbnail
          )
        )
      }

      return color.color_thumbnail.url
    },

    onAlertClick(ev) {
      ev.stopPropagation()

      const product = {
        productSlug: this.prismicProduct.uid,
        productName: this.prismicProduct.name,
        productId: this.shopifyProduct.id,
        productImage: 'https://',
        location: this.origin,
      }

      if (this.isSubscribed) {
        return this.unsubscribe(product)
          .then(() => {
            this.isSubscribed = this.account.isSubscribed(
              this.prismicProduct.uid
            )

            this.$track.bisUnSubscribe(this.prismicProduct.uid, this.origin)

            if (this.bisOnly) {
              this.showToaster(`<div>
                ${this.$t('product.alert.unlove', {
                  produit: this.prismicProduct.name,
                })}
              </div>
              `)
            } else {
              this.showToaster(`<div>
                ${this.$t('product.alert.deactivated', {
                  produit: this.prismicProduct.name,
                })}
              </div>
              `)
            }
          })
          .catch((err) => {
            this.$logError.captureException(err)
            this.subscribeError = true
          })
      }

      if (this.connectedEmail === '') {
        this.isAlertOpen = !this.isAlertOpen

        return
      }

      return this.subscribe(product)
        .then(() => {
          this.isSubscribed = this.account.isSubscribed(this.prismicProduct.uid)

          this.$nuxt.$emit(EVENTS.ASK_NOTIFICATION, this.origin)
          this.$track.bisSubscribe(
            this.account.email,
            this.prismicProduct.uid,
            this.origin
          )

          if (this.bisOnly) {
            this.showToaster(`<div>
                ${this.$t('product.alert.love', {
                  produit: this.prismicProduct.name,
                  email: this.account.email,
                })}
              </div>
              `)
          } else {
            this.showToaster(`<div>
                ${this.$t('product.alert.activated', {
                  produit: this.prismicProduct.name,
                  email: this.account.email,
                })}
              </div>
              `)
          }
        })
        .catch((err) => {
          this.$logError.captureException(err)
          this.subscribeError = true
        })
    },
    showToaster(text) {
      this.$toasted.clear()
      this.$toasted.show(text, {
        position: 'bottom-center',
        duration: 5000,
        keepOnHover: true,
        action: {
          text: '',
          icon: 'close',
          class: 'toasted__close',
          onClick: (_, toastObject) => {
            toastObject.goAway(0)
          },
        },
      })
    },
    hasSubscribe() {
      this.isAlertOpen = false
      this.isSubscribed = this.account.isSubscribed(this.prismicProduct.uid)
    },
    hasUnSubscribe() {
      this.isAlertOpen = false
      this.isSubscribed = this.account.isSubscribed(this.prismicProduct.uid)
    },
    afterCreateAlertEnter() {
      document
        .getElementById(`alert-${this.prismicProduct.uid}-bis-email`)
        .focus()
    },
  },
}
</script>

<style lang="scss">
.product-card-image-switch-enter-active,
.product-card-image-switch-leave-active {
  transition: opacity 0.8s;
}

.product-card-image-switch-enter,
.product-card-image-switch-leave-to {
  opacity: 0;
}

.product-card-v2--grid {
  position: relative;
  overflow: hidden;
  display: block;
  background: var(--tertiary-color);

  .product-card__price {
    .price {
      .number {
        font-size: var(--color-price-font-size);
        line-height: 1;
      }
    }
  }

  .product-card-v2__image {
    position: absolute;
    inset: 0;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(
        180deg,
        RGB(0 0 0 / 0) 61.92%,
        RGB(0 0 0 / 0.35) 88.02%
      );
      z-index: 2;

      @include mq($from: tablet) {
        background: linear-gradient(
          180deg,
          RGB(0 0 0 / 0) 52.92%,
          RGB(0 0 0 / 0.35) 87.49%
        );
      }
    }

    .lazy-image-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      picture {
        padding-top: 0 !important;
        height: 100%;
      }

      img {
        max-width: none;
        min-height: 100%;
        min-width: 100%;
        width: auto;
        height: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.5s ease-in-out;
      }
    }
  }

  .product-card-v2__content {
    position: absolute;
    bottom: 0;
    z-index: 3;
    left: 0;
    right: 0;
    padding: var(--spacing);

    .inner {
      display: flex;
      flex-direction: column;
    }

    @include mq($from: tablet) {
      padding: calc(var(--spacing) * 0.6) calc(var(--spacing) * 1.2);
    }
  }

  .product-card-v2__name {
    font-size: var(--h4-font-size);
    line-height: 1.1;
  }

  .product-card-v2__price {
    @include mq($from: tablet) {
      height: rem(34px);
    }
  }

  .product-card__colors {
    align-self: flex-start;
  }

  .product-card__colors--number {
    @include mq($until: tablet) {
      color: var(--secondary-color-5);
    }
  }

  .product-card-v2__agenda {
    font-size: var(--small-font-size);
    background: transparent;
    border: none;
    padding: 0;
    line-height: 1;
    cursor: pointer;

    .icon {
      width: rem(20px);
      height: rem(20px);
    }
  }

  .product-card-v2__loading {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: RGB(0 0 0 / 0.4);
  }

  .colors__matter {
    --line-color: RGB(255 255 255 / 0.7);

    flex-shrink: 0;

    .bg {
      &::after {
        box-shadow: 0 0 0 rem(1px) var(--line-color);
      }
    }

    &.active {
      --line-color: var(--secondary-color-4);

      .bg {
        &::after {
          box-shadow: 0 0 0 rem(2px) var(--line-color);
        }
      }
    }
  }

  .product-card-v2__countdown {
    @include mq($from: tablet) {
      margin-bottom: rem(46px);
    }
  }

  .create-alert:not(.create-alert--inline) {
    position: absolute;
    z-index: 5;
    background: var(--secondary-color);
    color: var(--tertiary-color);
    left: 0;
    right: 0;
    bottom: 0;
    padding: rem($spacing * 2);
    text-align: left;
    cursor: default;

    &__title {
      font-size: var(--h4-font-size);
      margin-bottom: rem($spacing * 0.4);
    }

    .input {
      flex-grow: 1;
    }

    @include mq($until: tablet) {
      form .action {
        width: 100%;
      }
    }

    @include mq($from: tablet) {
      form {
        display: flex;

        .action {
          height: 100%;
          padding-top: 0;
          padding-bottom: 0;

          &__inner {
            height: 100%;
          }

          &__text {
            white-space: nowrap;
          }
        }
      }

      .input {
        margin-right: calc(var(--spacing) * 0.6);
        margin-bottom: 0;
      }
    }
  }

  &.product-card-v2--link {
    @include on-hover-and-focus {
      .product-card-v2__image {
        img {
          transform: translate(-50%, -50%) scale(1.05);
        }
      }
    }
  }

  &.is-first {
    .product-card-v2__image {
      &::after {
        background: linear-gradient(
          180deg,
          RGB(56 24 0 / 0.5) 0%,
          RGB(56 24 0 / 0) 12%,
          RGB(56 24 0 / 0.035) 57.81%,
          RGB(56 24 0 / 0.35) 73.44%
        );

        @include mq($from: tablet) {
          background: linear-gradient(
            180deg,
            RGB(56 24 0 / 0) 60%,
            RGB(56 24 0 / 0.4) 100%
          );
        }
      }
    }
  }

  &.product-card-v2--agenda {
    @include mq($until: tablet) {
      .link--underline span::after {
        --link-underline-transform: scaleX(1);
      }

      .product-card-v2__content {
        padding-bottom: calc(var(--spacing) * 1.5);
      }
    }

    &.is-first {
      &:not(.product-card-v2--link) {
        @include mq($until: tablet) {
          .product-card-v2__content {
            width: 100%;
            padding-bottom: calc(var(--spacing) * 0.8);
            background: linear-gradient(
              180deg,
              RGB(56 24 0 / 0) 0%,
              RGB(56 24 0 / 0.2) 100%
            );
          }
        }

        @include mq($from: tablet) {
          .product-card-v2__content {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .inner {
              align-items: flex-start;
            }
          }
        }
      }
    }
  }

  &.product-card-v2--full {
    .product-card-v2__name {
      font-size: var(--h3-font-size);
    }

    .create-alert {
      @include mq($from: tablet) {
        left: 50%;
      }
    }

    &.is-first {
      .product-card-v2__content {
        padding: 0 var(--spacing) var(--spacing);
        inset: auto 0 0;

        @include mq($until: tablet) {
          .inner {
            align-items: center;
          }
        }

        @include mq($from: tablet) {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          inset: auto calc(var(--spacing) * 2) calc(var(--spacing) * 2);
        }
      }

      .product-card-v2__name {
        font-size: var(--countdown-font-size);
        font-weight: 900;
        line-height: 1;
        text-transform: uppercase;
        margin-left: rem(-2px);

        @include mq($until: tablet) {
          text-align: center;
          line-height: 1.1;
        }
      }

      &.product-card-v2--link {
        .product-card-v2__name {
          margin-bottom: calc(var(--spacing) * 0.2);

          @include mq($from: tablet) {
            margin-bottom: calc(var(--spacing) * 1.4);
          }
        }
      }

      &:not(.product-card-v2--link) {
        .product-card-v2__name {
          @include mq($from: tablet) {
            margin-bottom: calc(var(--spacing) * 0.8);
          }
        }
      }
    }
  }

  &.product-card-v2--small {
    .create-alert {
      @include mq($from: tablet) {
        .input {
          margin-right: 0;
        }

        form {
          display: block;

          .action {
            width: 100%;
            margin-top: var(--spacing);
          }

          .action__inner {
            min-height: rem(45px);
          }
        }
      }
    }
  }

  &.product-card-v2--home {
    .product-card-v2__content {
      @include mq($from: tablet) {
        inset: auto calc(var(--spacing) * 2) calc(var(--spacing) * 3);
      }
    }
  }
}

.product-card-v2--gift-card {
  &.product-card-v2--stock {
    .product-card-v2__content {
      padding-bottom: calc(var(--spacing) * 2.2);
    }
  }

  .product-card-v2__name {
    font-size: var(--h3-font-size);
    margin-bottom: 0;
    text-align: center;
  }
}

.product-card-v2--preco {
  .product-card-v2__price {
    align-items: center;
  }

  .product-card__price__inner {
    display: flex;
    align-items: center;
  }

  .product-card__price__text {
    @include visually-hidden;
  }

  .product-card-v2__content > .inner > * {
    justify-content: flex-start;
  }

  .product-card-v2__name {
    text-align: left;
  }
}
</style>
