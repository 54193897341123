var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-card-v2 product-card-v2--grid",class:{
    'is-first': _vm.isFirst,
    'product-card-v2--full': _vm.typeDesktop === 'one-per-line',
  }},[_c(_vm.mainComponent(),{tag:"component",attrs:{"to":_vm.$contextPrismicPath({
        type: 'collection',
        uid: `stock-${_vm.$route.params.context}`,
      })}},[_c('div',{staticClass:"product-card-v2__image"},[_c('transition',{attrs:{"mode":"in-out","name":"product-card-image-switch"}},[(_vm.images.length > 0)?_c('lazy-image',{attrs:{"images":_vm.images,"preload":_vm.isFirst,"force-show":_vm.isFirst,"provider":"shopify"}}):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"product-card-v2__content text-color-tertiary"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"d-flex",class:{
            'justify-center': !_vm.isFirst,
          }},[(_vm.isFirst)?_c('div',[_c('p',{staticClass:"lh-1 text-md-base text-left mb-0-8"},[_vm._v("\n              "+_vm._s(_vm.$t('lauch_day', {
                  day: _vm.$d(_vm.shopifyProduct.dates.start, 'weekday'),
                }))+"\n            ")])]):_c('div',{staticClass:"mb-1"},[_c('marketing-argument',{attrs:{"dates":_vm.shopifyProduct.dates,"aspect":"yellow-and-secondary"}})],1)]),_vm._v(" "),_c('p',{staticClass:"product-card-v2__name text-secondary mb-0-2",class:{
            'text-center': !_vm.isFirst,
          }},[_vm._v("\n          "+_vm._s(_vm.$t('stock.title'))+"\n        ")]),_vm._v(" "),(_vm.isTeasingMode)?_c('div'):(_vm.isFirst)?_c('p',{staticClass:"d-none d-md-block lh-1-2 pb-md-0-4"},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.$t('stock.alert.1')))]),_vm._v(" "),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.$t('stock.alert.2')))])]):_c('p',{staticClass:"text-small text-center lh-1-2 pt-0-2"},[_vm._v("\n          "+_vm._s(_vm.$t('stock.punchline'))+"\n        ")]),_vm._v(" "),(_vm.isTeasingMode)?_c('div',{staticClass:"pt-0-6 pb-md-0-4 mb-0-4 mb-md-1"},[_c('primary-button',{attrs:{"type":"button","text":_vm.$t('stock.teasing_action'),"with-arrow":""}})],1):(_vm.isFirst && _vm.$vssWidth > 796)?_c('div',{staticClass:"w-100 pb-md-0-4 mb-0-4 mb-md-1"},[_c('create-alert',{attrs:{"product":{
              productSlug: _vm.createAlertSlug,
              productName: '',
              productId: _vm.shopifyProduct.id,
              productImage: 'https://',
              location: _vm.origin,
            },"origin":_vm.origin,"button-size":"primary","inline":""},on:{"has-subscribe":function($event){return _vm.hasSubscribe()},"has-unsubscribe":function($event){return _vm.hasUnSubscribe()}}})],1):_c('div',{staticClass:"d-flex pt-0-4 pb-0-4 mb-0-6 mb-md-1",class:{
            'justify-center': !_vm.isFirst,
          }},[_c('button',{staticClass:"product-card-v2__agenda text-color-tertiary w-600",on:{"click":function($event){$event.preventDefault();return _vm.onAlertClick($event)}}},[_c('span',{staticClass:"d-flex align-center link--underline"},[_c('icon',{attrs:{"name":"bell","full":_vm.isSubscribed}}),_vm._v(" "),_c('span',{staticClass:"d-block ml-0-4"},[_vm._v(_vm._s(_vm.$t(
                  _vm.isSubscribed
                    ? 'alert.tooltip.remove'
                    : 'alert.tooltip.create'
                )))])],1)])])]),_vm._v(" "),(_vm.isFirst)?_c('div',{staticClass:"product-card-v2__countdown d-flex d-md-block flex-direction-column align-center mt-1 mt-md-0"},[_c('div',{staticClass:"d-flex justify-center justify-md-end flex-grow-1 mb-0-6"},[(_vm.isSellsAreOpen)?_c('div',{staticClass:"tag tag--sun-and-white"},[_c('icon',{attrs:{"name":"estimated-time"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('product.tag.last_before_end')))])],1):_c('div',{staticClass:"tag tag--yellow-and-secondary"},[_c('icon',{attrs:{"name":"calendar-2"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('product.tag.soon_preco')))])],1)]),_vm._v(" "),(_vm.isSellsAreOpen)?_c('big-count-down',{attrs:{"date":_vm.shopifyProduct.dates.end,"home":""}}):_c('big-count-down',{attrs:{"date":_vm.shopifyProduct.dates.start}})],1):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"from-bottom"},on:{"after-enter":_vm.afterCreateAlertEnter}},[(_vm.isAlertOpen)?_c('create-alert',{attrs:{"id":"alert-stock","product":{
          productSlug: _vm.createAlertSlug,
          productName: '',
          productId: _vm.shopifyProduct.id,
          productImage: 'https://',
          location: _vm.origin,
        },"title":_vm.$t('stock.alert.highlight_title', {
            date: _vm.$d(_vm.shopifyProduct.dates.start, 'short'),
          }),"text":_vm.$t('stock.alert.receive_alert'),"origin":_vm.origin,"button-size":"primary","with-close":""},on:{"has-subscribe":function($event){return _vm.hasSubscribe()},"has-unsubscribe":function($event){return _vm.hasUnSubscribe()},"close":function($event){_vm.isAlertOpen = false}}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }