<template>
  <button
    type="button"
    class="btn-link"
    :class="{ 'btn-link--active': active, 'link--underline': !skeleton }"
    @click="$emit('click', $event)"
  >
    <icon
      v-if="withIcon && iconPosition === 'left'"
      :name="icon"
      class="mr-0-2"
    />
    <span
      :class="{ 'skeleton skeleton--bar': skeleton }"
      :style="skeleton ? 'padding: 0 40px;' : ''"
    >
      <slot />
    </span>
    <icon
      v-if="withIcon && iconPosition === 'right'"
      :name="icon"
      class="ml-0-2"
    />
  </button>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
  name: 'ButtonLink',
  components: { Icon },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'arrow-left',
    },
    iconPosition: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'right'].includes(value),
    },
    skeleton: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.btn-link {
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  font-family: var(--primary-font-family);
  font-weight: 400;

  > span,
  .icon {
    vertical-align: middle;
  }

  > span {
    &::after {
      height: rem(2px);
      border-radius: 2px;
    }
  }

  &--active {
    > span {
      &::after {
        --link-underline-transform: scaleX(1);
        --link-underline-origin: 0% 50%;
      }
    }
  }
}
</style>
