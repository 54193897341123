var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slider p-relative",class:{
    'slider--scroll-bar': _vm.withScrollBar,
    'slider--autoplay': _vm.autoplay,
    'slider--gesture': !_vm.noGesture && !_vm.withScrollBar,
  },on:{"mousedown":_vm.onPointerDown,"touchstart":_vm.onPointerDown}},[(!_vm.ready)?_c('div',{staticClass:"slider__inner slider__inner--loading",style:(_vm.loadingInnerStyle)},[(_vm.numberOnScreen === 1)?_c('div',{staticClass:"skeleton",style:(_vm.loadingElementStyle)}):_vm._l((_vm.numberOfChildren),function(index){return _c('div',{key:index,staticClass:"skeleton",style:(_vm.loadingElementStyle)})})],2):_c('div',{staticClass:"slider__inner",style:({
      gridGap: `${_vm.gap}px`,
      gridTemplateColumns: `repeat(${_vm.numberOfChildren}, ${
        _vm.width ? _vm.width : _vm.childWidth
      }px)`,
      marginLeft: _vm.withScrollBar ? '' : `${_vm.left}px`,
      width: `${_vm.maxWidth}px`,
    })},[_vm._t("default")],2),_vm._v(" "),_c('div',{staticClass:"slider__controls"},[(_vm.autoplay)?_c('div',{staticClass:"slider__autoplay d-flex align-center text-label"},[_c('p',{staticClass:"mb-0 mr-0-4"},[_vm._v("01")]),_vm._v(" "),_vm._l((_vm.numberOfChildren),function(i){return _c('progress-bar',{key:i,staticClass:"ml-0-2 mr-0-2",style:(_vm.backTo0
            ? `--progress-transition-duration: 0.3s; --progress-transition-delay: ${
                (_vm.sliderPosition + 1 - i) * 0.3
              }s`
            : ''),attrs:{"value":i - 1 < _vm.sliderPosition
            ? 100
            : i - 1 === _vm.sliderPosition
            ? _vm.autoplayPercent
            : 0,"total":100},nativeOn:{"click":function($event){return _vm.sliderGoTo(i - 1)}}})}),_vm._v(" "),_c('p',{staticClass:"mb-0 ml-0-4"},[_vm._v("0"+_vm._s(_vm.numberOfChildren))])],2):_vm._e(),_vm._v(" "),(!_vm.hideNav && _vm.maxWidth > _vm.innerWidth)?_c('slider-nav',{attrs:{"as-square":_vm.navAsSquare,"hide-left":_vm.hidePrev,"hide-right":_vm.hideNext},on:{"prev":_vm.sliderPrev,"next":_vm.sliderNext}}):_vm._e(),_vm._v(" "),(_vm.pagination)?_c('div',{staticClass:"slider__number text-secondary"},[_c('p',{staticClass:"mb-0"},[_vm._v("\n        "+_vm._s(_vm.sliderPosition + 1)+" / "+_vm._s(_vm.numberOfChildren)+"\n      ")])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }