<template>
  <section
    v-waypoint="{
      active: detectInScreen,
      callback: onWaypoint,
      options: intersectionOptions,
    }"
    class="slice"
    :class="{
      'slice--in-screen': inScreen,
      'd-none-i': sliceToHide.includes(id),
    }"
    @click="$emit('click')"
  >
    <slot />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Slice',
  props: {
    id: { type: String, default: '' },
    detectInScreen: { type: Boolean, default: true },
  },
  data: () => ({
    intersectionOptions: {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: [0, 1],
    },
    inScreen: false,
    visibleSend: false,
  }),
  computed: {
    ...mapGetters({
      scroll: 'behavior/scroll',
      sliceToHide: 'behavior/sliceToHide',
    }),
  },
  methods: {
    onWaypoint(waypoint) {
      if (waypoint.going === this.$waypointMap.GOING_OUT && this.inScreen) {
        this.inScreen = false
        this.$emit('out', waypoint)
      }

      if (waypoint.going === this.$waypointMap.GOING_IN && !this.inScreen) {
        this.inScreen = true
        this.$emit('in', waypoint)

        if (!this.visibleSend && !this.scroll) {
          // this.$track.slice(this.$el.id)
          this.visibleSend = true
        }
      }
    },
  },
}
</script>
