var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{directives:[{name:"waypoint",rawName:"v-waypoint",value:({
    active: _vm.detectInScreen,
    callback: _vm.onWaypoint,
    options: _vm.intersectionOptions,
  }),expression:"{\n    active: detectInScreen,\n    callback: onWaypoint,\n    options: intersectionOptions,\n  }"}],staticClass:"slice",class:{
    'slice--in-screen': _vm.inScreen,
    'd-none-i': _vm.sliceToHide.includes(_vm.id),
  },on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }