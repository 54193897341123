<template>
  <div class="tag" :class="`tag--${tagClass}`">
    <icon :name="iconName" />

    <span v-if="showText" class="tag__text">{{ text }}</span>
    <span v-else-if="showStartDate" class="tag__text">
      {{ $d(dates.start, 'short') }}
    </span>
    <span v-else-if="showEndDate" class="d-flex align-center">
      <span style="padding-right: 0.125rem">{{
        $t('purchase.timer.only')
      }}</span>
      <client-only>
        <count-down
          :end="dates.end"
          :over-text="$t('purchase.timer.over')"
          show-unit
        />
      </client-only>
      <span>{{ $t('purchase.timer.left') }}</span>
    </span>
    <span v-else class="tag__text">
      <number-animated
        ref="number"
        :from="numberOfSells.from"
        :to="numberOfSells.to"
        :duration="0.5"
        @complete="numberOfSells.from = numberOfSells.to"
      />

      <span>{{ $t('purchase.nbPreco') }}</span>
    </span>
  </div>
</template>

<script>
import Icon from '@/components/icons/Icon'
import { MARKET_ARGUMENT, MARKET_ARGUMENT_TAG, STATUS } from '@/const'
import { EVENTS } from '@/const/events'

export default {
  name: 'MarketingArgument',
  components: {
    Icon,
    CountDown: () => ({
      component: import(
        '@/components/countdown/CountDown' /* webpackChunkName: 'countdown' */
      ),
    }),
    NumberAnimated: () => ({
      component: import(
        '@/components/number/Number' /* webpackChunkName: 'animated_number' */
      ),
    }),
  },
  props: {
    status: {
      type: String,
      default: STATUS.AGENDA,
    },
    marketing: {
      type: Object,
      default: () => ({
        type: MARKET_ARGUMENT.AUTOMATIQUE,
        tag: MARKET_ARGUMENT_TAG.AUTOMATIQUE,
        message: '',
      }),
    },
    dates: {
      type: Object,
      default: () => ({
        start: null,
        end: null,
      }),
    },
    inventory: {
      type: Number,
      default: 0,
    },
    aspect: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    numberOfSells: { from: 0, to: 0 },
  }),
  computed: {
    showText() {
      return (
        this.marketing.type === MARKET_ARGUMENT.TEXTE &&
        this.marketing.message !== ''
      )
    },
    showStartDate() {
      return this.dates.start && this.status === STATUS.AGENDA && !this.stock
    },
    showEndDate() {
      return this.dates.end && this.endIsSoon() && !this.stock
    },
    iconName() {
      switch (this.marketing.tag) {
        case MARKET_ARGUMENT_TAG.NEW:
        case MARKET_ARGUMENT_TAG.NEWCOLOR:
          return 'bell-2'
        case MARKET_ARGUMENT_TAG.TEASING:
          return 'calendar-2'
        case MARKET_ARGUMENT_TAG.TIMER:
          return 'estimated-time'
        case MARKET_ARGUMENT_TAG.NUMBER:
          return 'info-2'
        case MARKET_ARGUMENT_TAG.LASTCHANCE:
          return 'box'
        case MARKET_ARGUMENT_TAG.COCREATION:
          return 'pensil'
      }

      if (this.showStartDate) {
        return 'calendar-2'
      }

      if (this.showEndDate) {
        return 'estimated-time'
      }

      return 'info-2'
    },
    tagClass() {
      if (this.aspect) {
        return this.aspect
      }

      if (this.marketing.tag !== MARKET_ARGUMENT_TAG.AUTOMATIQUE) {
        switch (this.marketing.tag) {
          case MARKET_ARGUMENT_TAG.NEW:
          case MARKET_ARGUMENT_TAG.NEWCOLOR:
            return 'white-and-secondary'
          case MARKET_ARGUMENT_TAG.TIMER:
          case MARKET_ARGUMENT_TAG.LASTCHANCE:
            return 'black-and-yellow'
          case MARKET_ARGUMENT_TAG.TEASING:
            return 'darker-and-white'
          case MARKET_ARGUMENT_TAG.NUMBER:
          case MARKET_ARGUMENT_TAG.COCREATION:
            return 'default'
        }
      }

      if (this.showStartDate) {
        return 'darker-and-white'
      }

      if (this.showEndDate) {
        return 'black-and-yellow'
      }

      return 'default'
    },
    text() {
      if (this.showText) {
        return this.marketing.message
      }

      return ''
    },
  },
  beforeDestroy() {
    this.$nuxt.$off(EVENTS.PRODUCT_UPDATED, this.updateNumberOfSells)
  },
  mounted() {
    this.numberOfSells = { from: this.inventory, to: this.inventory }

    this.$nuxt.$on(EVENTS.PRODUCT_UPDATED, this.updateNumberOfSells)
  },
  methods: {
    endIsSoon() {
      if (this.dates.end) {
        const now = new Date()
        const endTime = this.dates.end.getTime()
        const nowTime = now.getTime()

        if (endTime > nowTime) {
          const timeDuration = endTime - nowTime
          const numberOfDays = Math.floor(timeDuration / (1000 * 60 * 60 * 24))

          if (numberOfDays <= 7) {
            return true
          }
        }
      }

      return false
    },
  },
}
</script>

<style lang="scss">
.tag {
  display: flex;
  align-items: center;
  padding: rem(4px) var(--spacing);
  font-size: var(--small-font-size);
  line-height: rem(15px);
  border-radius: 45px;
  color: var(--tertiary-color);
  background: RGB(0 0 0 / 0.4);
  overflow: hidden;

  .icon {
    width: rem(16px);
    height: rem(16px);
    margin-right: calc(var(--spacing) * 0.4);
  }

  &__text {
    display: flex;
    align-items: center;

    > * + * {
      margin-left: calc(var(--spacing) * 0.2);
    }
  }

  .countdown__separator {
    margin: 0;
    padding: 0 rem(2px);
  }

  &.tag--black-and-yellow {
    color: var(--yellow-color);
  }

  &.tag--darker-and-white {
    background: RGB(0 0 0 / 0.6);
    font-size: var(--small-font-size);
  }

  &.tag--white-and-secondary {
    color: var(--secondary-color);
    background: RGB(255 255 255 / 0.9);
  }

  &.tag--yellow-and-secondary {
    color: var(--secondary-color);
    background: var(--yellow-color);
  }

  &.tag--sun-and-white {
    color: var(--tertiary-color);
    background: var(--sun-color);
  }
}
</style>
