export function extractMetafieldsOfCollection(collection: any) {
  const mm: any = {}

  for (const metafield of collection.metafields) {
    if (metafield) {
      mm[metafield.key] =
        metafield.type === 'date_time'
          ? new Date(metafield.value)
          : metafield.value
    }
  }

  return mm
}
