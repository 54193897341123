<template>
  <nav class="slider-nav" :class="{ 'slider-nav--as-square': asSquare }">
    <transition name="fade">
      <primary-button
        v-if="!hideLeft"
        :text="$t('prev')"
        :aspect="asSquare ? 'light' : 'transparent'"
        with-arrow
        arrow-direction="left"
        :arrow-is-icon="iconCarret"
        :icon="iconCarret ? 'carret-left' : ''"
        text-visually-hidden
        class="slider-nav__prev"
        @touchstart="touch($event, 'touchstart')"
        @touchmove="touch($event, 'touchmove')"
        @touchend="touch($event, 'touchend')"
        @click.prevent="prev"
      />
    </transition>
    <transition name="fade">
      <primary-button
        v-if="!hideRight"
        :text="$t('next')"
        :aspect="asSquare ? 'light' : 'transparent'"
        with-arrow
        arrow-direction="right"
        :arrow-is-icon="iconCarret"
        :icon="iconCarret ? 'carret-right' : ''"
        text-visually-hidden
        class="slider-nav__next"
        @touchstart="touch($event, 'touchstart')"
        @touchmove="touch($event, 'touchmove')"
        @touchend="touch($event, 'touchend')"
        @click.prevent="next"
      />
    </transition>
  </nav>
</template>

<script>
import PrimaryButton from '@/components/buttons/Primary'

export default {
  name: 'SliderNav',
  components: {
    PrimaryButton,
  },
  props: {
    asSquare: { type: Boolean, default: false },
    iconCarret: { type: Boolean, default: false },
    hideLeft: { type: Boolean, default: false },
    hideRight: { type: Boolean, default: false },
  },
  methods: {
    touch(ev, type) {
      this.$emit(type, ev)
    },
    prev(ev) {
      this.$track.event('slider_nav', {
        direction: 'prev',
      })
      this.$emit('prev', ev)
    },
    next(ev) {
      this.$track.event('slider_nav', {
        direction: 'next',
      })
      this.$emit('next', ev)
    },
  },
}
</script>

<style lang="scss">
.slider-nav {
  .action {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: var(--spacing);
    z-index: 10;
    height: auto;

    &__inner {
      color: var(--tertiary-color);
    }

    @include mq($from: tablet) {
      padding: calc(var(--spacing) * 2);
    }

    svg {
      width: rem(26px);
      height: rem(26px);
    }
  }

  &__prev {
    left: 0;
  }

  &__next {
    right: 0;
  }

  &--as-square {
    .action {
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
      box-shadow: none;
      padding: var(--spacing);

      &__inner {
        color: var(--secondary-color);
      }

      &:not(.action--error) {
        @include on-hover-and-focus {
          box-shadow: none;
        }

        .icon--arrow-left,
        .icon--arrow-right {
          transform: none;
        }
      }
    }
  }
}
</style>
