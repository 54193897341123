export const NUMBER_BY_LINE = {
  ONE_PER_LINE: '1 produit',
  ONE_PER_LINE_LANDSCAPE: '1 produit paysage',
  TWO_PER_LINE: '2 produits',
  THREE_PER_LINE: '3 produits',
  ONE_LINE_PORTRAIT_LANDSCAPE: '1 produit portrait - 1 produit paysage',
  ONE_LINE_LANDSCAPE_PORTRAIT: '1 produit paysage - 1 produit portrait',
}

export const RATIO = {
  PORTRAIT: 640 / 800,
  LANDSCAPE: 990 / 640,
}
